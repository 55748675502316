/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'; 
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom'
import { useScroll, useDebounceEffect, useDebounceFn, useHover, useInViewport } from 'ahooks' 
import Login from 'components/Login'
import Agreement from 'components/Agreement'
import { interceptAdd } from 'utils/utils'
import { useWeb3React } from '@web3-react/core'
import Web3Form from 'components/Web3Form'
import { StyledContainerLeft, StyledContainer1, StyledContainer2, StyledContainer3, StyledContainer4, StyledContainer5, StyledContainer6, StyledContainer7 } from './indexStyles'
import './index.css'

const StyleBase = styled.div``

const Wrapper = styled(StyleBase)<{isMobile?: boolean,StyledContainer1Ref: any,StyledContainer2Ref: any,StyledContainer3Ref: any,StyledContainer4Ref: any,StyledContainer5Ref: any,StyledContainer6Ref: any,StyledContainer7Ref: any }>`
  min-height: 100vh;
`
const StyledHeader = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'row' };
    justify-content: end;
    color: #FFFFFF;
    padding-top: 20px;
    font-size: 15px;
    font-family: Copperplate-Bold, Copperplate;
    font-weight: bold;
    color: #000F19;
    img{
      position: absolute;
      top: 20px;
      left: 0px;
      width: 39.92px;
      height: 50.39px;
      margin-left: 19px;
      
    }
    .AEGIS {
      position: absolute;    
      top: 34px;
      left: 68px;
      font-size: 16px;
      font-family: 'Copperplate-Bold-x';
      color: #FFFFFF;
    }
    .me-txt {
      position: absolute;    
      top: 30px;
      right: 0px;
      display: flex;
      margin-right: 16px;
    }
    .styledHeaderTextM {
      width: 83px;
      height: 33px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 2px solid #505763;
      font-size: 14px;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 30px;
    }
    .styledHeaderTextC {
      width: 146px;
      height: 33px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 2px solid #505763;
      font-size: 14px;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 30px;
      margin-left: 13px;
    }
    .styledHeaderTextC-hover:hover {
      color: #FEFDFE;
      background: #9333EA;
      border: 0px solid #505763;
      line-height: 35px;
    }
`

const StyledA1Right = styled(StyleBase)<{isMobile?: boolean }>`
    position: absolute;
    top: 109px;
    margin-left: 12px;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    background-size: auto 100%;
    color: #000F19;
    transform: ${({isMobile}) => isMobile ?  'translateX(66%) translateY(12%)' : '' };
    .a {
      height: 130px;
      font-size: 91px;
      font-weight: bold;
      color: #000F19;
      line-height: 130px;
      z-index: 5;
      img {
        width: 76px;
        height: 96px;
      }
    }
    .a-j {
      animation: A-J 3s;
      animation-fill-mode: forwards;
    }
    .a-c {
      animation: A-C 0.5s;
      animation-fill-mode: forwards;
    }
    .protect {
      font-size: 16px;
      font-family: Copperplate-Bold-x;
      font-weight: 800;
      color: #000F19;
      margin-top: 10px;
      z-index: 5;
    }
    
    @keyframes A-J {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }  
    @keyframes A-C {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    } 
`

const Dream = styled(StyleBase)<{isMobile?: boolean }>`    
        position: absolute;    
        :root {
          --deg: 1;
          --x: -50%;
          --y: -50%;
        }

        div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(var(--x, -50%), var(--y, -50%)) rotate(0deg);
          font-size: 20vmin;
          width: 250px;
          height: 250px;
          border-radius: 90% 95% 85% 105%;
          background: #0f0;
          mix-blend-mode: screen;
          filter: hue-rotate(0deg);
          animation: wobble calc(150ms * var(--t)) linear infinite;
          transform-origin: -var(--y) -var(--x);
          box-shadow: 0 0 .5em .2em #000 inset, 0 0 .15em 0 #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          &::after {
            font-size: 1em;
            // content: '☻';
            white-space: nowrap;
          }
          &:nth-child(1) {
            --x: -53%;
            --y: -53%;
            --t: 37;
          }
          &:nth-child(2) {
            --x: -47%;
            --y: -52%;
            --t: 58;
          }
          &:nth-child(3) {
            --x: -45%;
            --y: -50%;
            --t: 46;
          }
          &:nth-child(4) {
            --x: -53%;
            --y: -45%;
            --t: 72;
          }
          &:nth-child(5) {
            --x: -55%;
            --y: -45%;
            --t: 62;
          }
        }

        @keyframes wobble {
          to {
            filter: hue-rotate(360deg);
            transform: translate(var(--x), var(--y)) rotate(360deg);
          }
        }
`


const StyledA1Left = styled(StyleBase)<{isMobile?: boolean }>`
    position: absolute;
    top: 371px;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    justify-content: center;
    align-items: start;
    color: #FFFFFF;
    margin-top: 50px;
    .Upgrade {
      width: 330px;
      // font-family: Courier New-Bold, Courier New;
      font-weight: bold;
      font-size: 13px;
      font-weight: 400;
      color: #9333EA;
      .avalanche-m {
        border-bottom: 2px solid #9333EA;
        font-size: 14px;
        font-weight: 600;
      }
      .ff {
        width: 20px;
        height: 20px;
        background: #1673FF;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }
    .c-m-1 {
      animation: C-m-1 1.5s;
      animation-fill-mode: forwards;
    }
    .c-m-2 {
      animation: C-m-2 1.5s;
      animation-fill-mode: forwards;
    }
    .j-m-1 {
      animation: J-m-1 1.5s;
      animation-fill-mode: forwards;
    }
    
    .j-m-2 {
      animation: J-m-2 1.5s;
      animation-fill-mode: forwards;
    }
    .your {
      width: 313px;
      font-size: 30px;
      font-family: Arial-Bold, Arial;
      font-weight: bold;
      color: #FFFFFF;
      margin-top: 22px;
    }
    .aegis {
      width: 307px;
      height: 60px;
      font-size: 16px;
      font-family: Courier New-Regular,Courier New;
      font-weight: 400;
      color: #E1E1E1;
      margin-top: 22px;
      margin-bottom: 12px;
    }
    .join {    
      width: 207px;
      height: 45px;
      background: #9333EA;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 12px;
      font-family: Copperplate-Bold-x;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      line-height: 45px;
      margin-top: 22px;
    }
    .join:hover {
      color: #FEFDFE;
      background: #B360FF;
    }
    @keyframes J-m-1 {
        0% {
          opacity: 0;
          transform: translateX(-100px);
        }
        100% {
          opacity: 1;
          transform: translateX(7%);
        }
    }
    @keyframes J-m-2 {
        0% {
          opacity: 0;
          transform: translateX(-32vw) translateY(-288px);
        }
        100% {
          opacity: 1;
          transform: translateX(-19vw) translateY(-288px);
        }
    }
    @keyframes C-m-1 {
        0% {
          opacity: 1;
          transform: translateX(7%);
        }
        100% {
          opacity: 0;
          transform: translateX(-100px);
        }
    }
    @keyframes C-m-2 {
        0% {
          opacity: 1;
          transform: translateX(-19vw) translateY(-288px);
        }
        100% {
          opacity: 0;
          transform: translateX(-32vw) translateY(-288px);
        }
    }
`

export default function Index() {
  
  const history = useHistory()
  const { account } = useWeb3React()
  const StyledContainer1Ref = useRef(null);
  const StyledContainer2Ref = useRef(null);
  const StyledContainer3Ref = useRef(null);
  const StyledContainer4Ref = useRef(null);
  const StyledContainer5Ref = useRef(null);
  const StyledContainer6Ref = useRef(null);
  const StyledContainer7Ref = useRef(null);
  const Container1RefVisibility = useInViewport(StyledContainer1Ref);
  const Container2RefVisibility = useInViewport(StyledContainer2Ref);
  const Container3RefVisibility = useInViewport(StyledContainer3Ref);
  const Container4RefVisibility = useInViewport(StyledContainer4Ref);
  const Container5RefVisibility = useInViewport(StyledContainer5Ref);
  const Container6RefVisibility = useInViewport(StyledContainer6Ref);
  const zcRef = useRef(null);
  const WrapperRef = useRef(null);
  const StyledContainer4Num = useRef(null);
  const [styledContainer4NumFour, setStyledContainer4NumFour] = useState(0);
  const [pageClass, setPageClass] = useState('roll');
  const [web3FormIsModalOpen, setWeb3FormIsModalOpen] = useState(false);
  const StyledContainer4Num1 = useRef(null);
  const StyledContainer4Num2 = useRef(null);
  const StyledContainer4Num3 = useRef(null);
  const StyledContainer4Num4 = useRef(null);
  const isHoveringNum1 = useHover(StyledContainer4Num1);
  const isHoveringNum2 = useHover(StyledContainer4Num2);
  const isHoveringNum3 = useHover(StyledContainer4Num3);
  const isHoveringNum4 = useHover(StyledContainer4Num4);
  const StyledContainer1Num = useRef(null); 
  const [page1Num, setPage1Num] = useState(0);
  const [meunLink, setMeunLink] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cookies,setCookies] = useState(Cookies.getJSON('cookie-policy__accept'))
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 700px)').matches,
  );



  useEffect(() => { 
    clearInterval(StyledContainer4Num.current)
    if(isHoveringNum1) {
      setStyledContainer4NumFour(0)
    } else if(isHoveringNum2) {
      setStyledContainer4NumFour(1)
    } else if(isHoveringNum3) {
      setStyledContainer4NumFour(2)
    } else if(isHoveringNum4) {
      setStyledContainer4NumFour(3)
    }
    let num = styledContainer4NumFour;
    StyledContainer4Num.current = setInterval(() => { 
      num ++
      setStyledContainer4NumFour(num%4)
    }, 3000)
  }, [isHoveringNum1, isHoveringNum2, isHoveringNum3, isHoveringNum4])



  useEffect(() => {
    setPage1Num(0)
    let num = 0;
    StyledContainer1Num.current = setInterval(() => { 
      num ++
      setPage1Num(num%3)
    }, 1500)
    window
      .matchMedia('(min-width: 700px)')
      .addEventListener('change', (e) => setMatches(e.matches));
    return () => { 
      clearInterval(StyledContainer4Num.current)
      clearInterval(StyledContainer1Num.current)
    }
  }, []);
  useDebounceEffect(() => { 
    if(Container5RefVisibility?.[0]){
      clearInterval(StyledContainer4Num.current)
      setStyledContainer4NumFour(0)
      let num = 0;
      StyledContainer4Num.current = setInterval(() => { 
        num ++
        setStyledContainer4NumFour(num%4)
      }, 3000)
    } else {
      clearInterval(StyledContainer4Num.current)
    }
    return () => { 
      clearInterval(StyledContainer4Num.current)
     }
  }, 
  [Container5RefVisibility?.[0]],
  {
    wait: 300,
  },)
  useEffect(() => { 
    WrapperRef.current.style.overflowX = 'hidden'
  }, [matches])
  return (
    <Wrapper 
      ref={WrapperRef} 
      StyledContainer1Ref={StyledContainer1Ref}
      StyledContainer2Ref={StyledContainer2Ref}
      StyledContainer3Ref={StyledContainer3Ref}
      StyledContainer4Ref={StyledContainer4Ref}
      StyledContainer5Ref={StyledContainer5Ref}
      StyledContainer6Ref={StyledContainer6Ref}
      StyledContainer7Ref={StyledContainer7Ref}
    >
      <main className={`${pageClass} roll-all`}>
        <div style={{
          background: "url('/images/home/mbj_1.png')",
          backgroundSize: '100% 100%',
          height: !matches?'852px':'522px'
        }} ref={StyledContainer1Ref}>
          <StyledContainerLeft isZc={meunLink}>
            <div ref={zcRef} className='zk' onClick={() => { 
            setMeunLink((pre) => !pre)
           }}> <img src="/images/home/arrow.png" alt="" /> </div>

            <a href="https://twitter.com/aegishq" target="_blank" rel="noreferrer"><p className='left_img_m_1 corsor-pointer' /></a>
            <a href="https://aegishq.medium.com/" target="_blank" rel="noreferrer"><p className='left_img_m_2 corsor-pointer' /></a>
          </StyledContainerLeft>
          <StyledHeader>
            <img className='corsor-pointer' src="/images/home/logo.svg" alt='' onClick={() => { 
              window.location.href = '/'
             }}/>
            <span className='AEGIS'>AEGIS</span>
            <div className='me-txt'>
              {/* <div className='styledHeaderTextM'>Medium</div> */}
               <Login
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}>
              <div className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC styledHeaderTextC-hover corsor-pointer'}>{interceptAdd(account)}</div></Login>
            </div>
          </StyledHeader>
          <StyledContainer1>
            <StyledA1Right isMobile={matches}> 
              <Dream>
                <main>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </main>
              </Dream> 
              <div className={Container1RefVisibility?.[0] ? 'a a-j' : 'a a-c'}>
                <img className='img1' src="/images/home/A1.png" alt="" />
              </div>
              <div className={Container1RefVisibility?.[0] ? 'protect a-j' : 'protect a-c'}>
                AEGIS
              </div>
            </StyledA1Right>
            <StyledA1Left isMobile={matches}>
              <div className={Container1RefVisibility?.[0] ? `${!matches?'Upgrade j-m-1' : 'Upgrade j-m-2'}` : `${!matches?'Upgrade c-m-1' : 'Upgrade c-m-2'}`}>
                <span style={{color: '#FFFFFF'}}>Upgrade security and privacy on</span> <span className='avalanche-m'>{page1Num === 0 && 'Ethereum'}{page1Num === 1 && 'BNB Chain'}{page1Num === 2 && 'Polygon'}</span>
              </div>
              <div className={Container1RefVisibility?.[0] ? `${!matches?'your j-m-1' : 'your j-m-2'}` : `${!matches?'your c-m-1' : 'your c-m-2'}`}>Your AI Security Guard of Web3</div>
              <div className={Container1RefVisibility?.[0] ? `${!matches?'aegis j-m-1' : 'aegis j-m-2'}` : `${!matches?'aegis c-m-1' : 'aegis c-m-2'}`}>Aegis is web3 security guard powered by AI technology that helps to solve the security issues.</div>
              <div className={Container1RefVisibility?.[0] ? `${!matches?'corsor-pointer join j-m-1' : 'corsor-pointer join j-m-2'}` : `${!matches?'join c-m-1' : 'join c-m-2'}`} 
                onClick={() => { 
                  if(account){
                    // history.push('/JoinWhitelist')
                    setWeb3FormIsModalOpen(true)
                  } else {
                    setIsModalOpen(true)
                  }
                }}>Join whitelist  <img style={{width: '16.29px', height: '16.29px', marginLeft: '15px'}} src="/images/home/right_z.png" alt=''  /></div>
            </StyledA1Left>
          </StyledContainer1>
          {
            !cookies && <Agreement setCookies={setCookies}/>
          }
        </div>
        <StyledContainer2 isMobile={matches} ref={StyledContainer2Ref}>
          <div className={Container2RefVisibility?.[0] ? 'cardRight' : 'cardRight-c'}>
            <img className='img1' src="/images/home/2-middle.png" alt="" />
            <img className='img2' src="/images/home/2-left.png" alt="" />
            <img className='img3' src="/images/home/2-right.png" alt="" />
          </div>
          <div className={Container2RefVisibility?.[0] ? `${!matches?'Container2Left Container2Left-j-1':'Container2Left Container2Left-j-2'}` : `${!matches?'Container2Left Container2Left-c-1':'Container2Left Container2Left-c-2'}` }>
            <div className='aegis'><span >Aegis</span> <span style={{color: '#9333EA'}}>Protect</span></div>
            <div className='browser'>A browser extension keeps you away from scams.</div>
            <div className='buttonJon'>
              <div className='join corsor-pointer' onClick={() => { 
                  if(account){
                    // history.push('/JoinWhitelist')
                    setWeb3FormIsModalOpen(true)
                  } else {
                    setIsModalOpen(true)
                  }
                }}>Join whitelist   <img style={{width: '16.29px', height: '16.29px', marginLeft: '15px'}} src="/images/home/right_z.png" alt=''  /></div>
              <div className='joinImg'>
                <img className='img1' src="/images/home/sz.png" alt="" />
                <img className='img2' src="/images/home/gg.png" alt="" />
                <img className='img3' src="/images/home/sh.png" alt="" />
              </div>
            </div>
          </div>
        </StyledContainer2>
        {/* <StyledContainer3 isMobile={matches} ref={StyledContainer3Ref}>
          <div className={Container3RefVisibility?.[0] ? 'Container3Right' : 'Container3Right-C'}>
            <img className='img1' src="/images/home/3-middle.png" alt="" />
            <img className='img2' src="/images/home/3-left.png" alt="" />
            <img className='img3' src="/images/home/3-right.png" alt="" />
          </div>
          <div className={Container3RefVisibility?.[0] ? `${!matches?'Container3Left Container3Left-j-1':'Container3Left Container3Left-j-2'}` : `${!matches?'Container3Left Container3Left-c-1':'Container3Left Container3Left-c-2'}`}>
            <div className='aegis'><span style={{color: '#fff'}}>Aegis</span> <span style={{color: '#9333EA'}}>Wallet</span></div>
            <div className='a'>A mobile wallet that protects assets and preserves privacy.</div>
          </div>
        </StyledContainer3>       */}
        <StyledContainer4 isMobile={matches} ref={StyledContainer4Ref}>
          <div className='boxCircle'>
            <div className='circle'>
              A
            </div>
            <div className='circle1'/>
            <div className='circle2' />
            <div className='circle3' />
            <div className='circle4' />
            <div className={Container4RefVisibility?.[0] ? 'circle5 circle5-j' : 'circle5 circle5-c' }>
              <div style={{marginTop: 13}}>
                <div>
                  <img className='img1' src="/images/home/containerETH.png" alt="" />
                  <div>Ethereum</div>
                </div>
                <div style={{marginLeft: 10}}>
                  <img className='img1' src="/images/home/Polygon.png" alt="" />
                  <div>Polygon</div>
                </div>
              </div>
              <div style={{marginBottom: 13}}>
                <div>
                  <img className='img1' src="/images/home/c452.png" alt="" />
                  <div>BNB Chain</div>
                </div>
                {/* <div style={{marginTop: 8}}>
                  <img className='img1' src="/images/home/hh.png" alt="" />
                  <div>ETH</div>
                </div> */}
              </div>
            </div>
            <div className={Container4RefVisibility?.[0] ? 'circle6 circle6-j' : 'circle6 circle6-c' }>
              <div>
                <div>
                  <div className='img-box'>
                    <img className='img1' src="/images/home/m-containeNFTr.jpg" alt="" />
                  </div>
                  <div style={{marginLeft: 8}} className='img-box'>
                    <img className='img1' src="/images/home/m4-2.jpg" alt="" />
                  </div>
                </div>
                <div style={{marginTop: 8}}>
                  <div className='img-box'>
                    <img className='img1' src="/images/home/m4-3.jpg" alt="" />
                  </div>
                  <div style={{marginLeft: 8}} className='img-box'>
                    <img className='img1' src="/images/home/m4-4.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className={Container4RefVisibility?.[0] ? 'circle7 circle7-j' : 'circle7 circle7-c'}>
              <div>
                <img className='img1' src="/images/home/p_b.png" alt="" /><span>Protect</span>
              </div>
              <div>
                <img className='img1' src="/images/home/w_b.png" alt="" /><span>Wallet</span>
              </div>
              {/* <div>
                <img className='img1' src="/images/home/s.png" alt="" /><span>Shadow-mode</span>
              </div> */}
              <div>
                <img className='img1' src="/images/home/s_b.png" alt="" />
                <span>Security Brain</span>
              </div>
            </div>
          </div>
        </StyledContainer4>
        <StyledContainer5 isMobile={matches} ref={StyledContainer5Ref}>
          <div className='Container5Box'>
            <div className={Container5RefVisibility?.[0] ? `${!matches?'circle circle-j-1':'circle circle-j-2'}` : `${!matches?'circle circle-c-1':'circle circle-c-2'}`}>
              <div>
                AI
              </div>
              <div className={Container5RefVisibility?.[0] ? 'security' : 'security' }>
                SECURITY BRAIN 
              </div>
            </div>
            <div className={Container5RefVisibility?.[0] ? `${!matches?'ellipse ellipse-j-1':'ellipse ellipse-j-2'}` : `${!matches?'ellipse ellipse-c-1':'ellipse ellipse-c-2'}` }>
              <div ref={StyledContainer4Num2} className={styledContainer4NumFour === 1 ? 'fourCircleBox transformBox1 fourCircleBoxHover': 'fourCircleBox transformBox1' }>
                <div  className='circleX'> <div /></div>
                <div>
                  <div className='txt1'>ALERTS</div>
                  <div className='txt2 txt2_2'>Alerts to malicious smart contracts and websites</div>
                </div>
              </div>

              
              <div ref={StyledContainer4Num1} className={styledContainer4NumFour === 0 ? 'fourCircleBox transformBox2 fourCircleBoxHover' : 'fourCircleBox transformBox2'}>
                <div  className='circleX'>  <div /></div>
                <div>
                  <div className='txt1'>TRANSPARENCY</div>
                  <div className='txt2 txt2_1'>Provide the transparency of transactions</div>
                </div>
              </div>

              
              <div ref={StyledContainer4Num4} className={styledContainer4NumFour === 3 ? 'fourCircleBox transformBox3 fourCircleBoxHover' : 'fourCircleBox transformBox3'}>
                <div  className='circleX'>  <div /></div>
                <div>
                  <div className='txt1'>SHADOW-MODE</div>
                  <div className='txt2 txt2_4'>Easy to create shadow wallets and preserve transaction privacy</div>
                </div>
              </div>

              
              <div  ref={StyledContainer4Num3} className={styledContainer4NumFour === 2 ? 'fourCircleBox transformBox4 fourCircleBoxHover' : 'fourCircleBox transformBox4'}>
                <div  className='circleX'>  <div /></div>
                <div>
                  <div className='txt1'>REVOKE</div>
                  <div className='txt2 txt2_3'>Allowances management</div>
                </div>
              </div>
            </div>

            <div className={Container5RefVisibility?.[0] ? `${!matches?'txt3 txt3-j-1':'txt3 txt3-j-2'}` : 'txt3 txt3-c'}>
              Based on security big data analysis, the Al security brain uses key capabilities such as threat intelligence, and knowledge base to improve the detection and discovery capabilities of scams and malicious activities.
            </div>
            
          </div>
        </StyledContainer5>
        <StyledContainer6 isMobile={matches} ref={StyledContainer6Ref}>
          <div className='Container7Box'>
            <img className={Container6RefVisibility?.[0] ? 'img1 circle6-j' : 'img1 circle6-c'} src="/images/home/container6_z.png" alt="" />
            <div className={Container6RefVisibility?.[0] ? 'txtBox txtBox1 circle6-j' : 'txtBox txtBox1  circle6-c' }>
              <div className={Container6RefVisibility?.[0] ? 'txt1 box-txt-j' : 'txt1 box-txt-c'}><div>Transparency</div></div>
              <div className={Container6RefVisibility?.[0] ? 'txt2 box-txt-j' : 'txt2 box-txt-c'}><div>Transaction simulation,know what you&apos;re signing</div></div>
              <div className='line line1'/>
            </div>
            
            <div className={Container6RefVisibility?.[0] ? 'txtBox txtBox2 circle6-j' : 'txtBox txtBox2  circle6-c' }>
              <div className={Container6RefVisibility?.[0] ? 'txt1 box-txt-j' : 'txt1 box-txt-c'}>Privacy</div>
              <div className={Container6RefVisibility?.[0] ? 'txt2 box-txt-j' : 'txt2 box-txt-c'}>Protect your privacy </div>
              <div className='line line2'/>
            </div>
            
            <div className={Container6RefVisibility?.[0] ? 'txtBox txtBox3 circle6-j' : 'txtBox txtBox3  circle6-c' }>
              <div className={Container6RefVisibility?.[0] ? 'txt1 box-txt-j' : 'txt1 box-txt-c'}>Usability</div>
              <div className={Container6RefVisibility?.[0] ? 'txt2 box-txt-j' : 'txt2 box-txt-c'}>Easy to use and user friendly experiences </div>
              <div className='line line3'/>
            </div>
            
            <div className={Container6RefVisibility?.[0] ? 'txtBox txtBox4 circle6-j' : 'txtBox txtBox4  circle6-c' }>
              <div className={Container6RefVisibility?.[0] ? 'txt1 box-txt-j' : 'txt1 box-txt-c'}>Chain-agnostic</div>
              <div className={Container6RefVisibility?.[0] ? 'txt2 box-txt-j' : 'txt2 box-txt-c'}>Multi-chain support </div>
              <div className='line line4'/>
            </div>
          </div>
        </StyledContainer6>
        
        <StyledContainer7 isMobile={matches} ref={StyledContainer7Ref}>
          <div className='Container7Box'>
            {
              // !matches ?
              // <>
              //   <div className='lmgbox'>
              //     <div className='img-box1' />
              //     <div className='img-box2' />
              //     <div className='img-box3' />
              //     <div className='img-box4' />
              //     <div style={{width: '152px'}}>
              //       <img className='img1' src="/images/home/baidu.png" alt="" />
              //       <img className='img2' src="/images/home/huobi.png" alt="" />
              //       <img className='img3' src="/images/home/motorolo.png" alt="" />
              //       <img className='img4' src="/images/home/tenxun.png" alt="" />
              //     </div>
              //     <div>
              //       <img className='img5' src="/images/home/360.png" alt="" style={{marginBottom: 46, marginTop: 5}} />
              //       <img className='img6' src="/images/home/openpasp.png" alt="" style={{marginBottom: 39}} />
              //       <img className='img7' src="/images/home/Teaclave.png" alt="" />
              //     </div>
              //   </div>
              // </> :
              // <>
              //   <div className='lmgboxp'>
              //     <div className='img-box1' />
              //     <div className='img-box2' />
              //     <div className='img-box3' />
              //     <div className='img-box4' />
              //     <div>
              //       <img className='img1' src="/images/home/baidu.png" alt="" />
              //       <img className='img3' src="/images/home/motorolo.png" alt="" />
              //       <img className='img2' src="/images/home/huobi.png" alt="" />
              //     </div>
              //     <div>
              //       <img className='img5' src="/images/home/360.png" alt=""/>
              //       <img className='img6' src="/images/home/openpasp.png" alt=""/>
              //       <div style={{width:122}}>
              //         <img className='img7' src="/images/home/Teaclave.png" alt="" />
              //       </div>
              //     </div>
              //     <div>
              //       <img className='img4' src="/images/home/tenxun.png" alt="" />
              //     </div>
              //   </div>
              // </>
            }
            <div className='footer'>
              <div className='footerLeft'>
                <div style={{width: '181px', marginBottom: '13px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <img src="/images/home/logo.png" alt=''  /> <span>AEGIS</span>
                </div>
                <div style={{width: '217px', marginBottom: matches ? '' : '30px'}}>Your AI Security Guard of Web3</div>
              </div>
              <div className='footerRight'>
                <div>
                 <span onClick={() => { history.push('/TermsOfService') }}>Terms of Service</span> <span onClick={() => { history.push('/PrivacyPolicy') }}>Privacy Policy</span> <span><a href="mailto:hi@aegis.top">hi@aegis.top</a></span>
                  <div style={{marginTop: '13px'}}>Copyright @2022 Aegis Labs, Inc. All rights reserved.</div>
                </div>
              </div>
            </div>
          </div>
        </StyledContainer7>
      </main>
      <Web3Form web3FormIsModalOpen={web3FormIsModalOpen} setWeb3FormIsModalOpen={setWeb3FormIsModalOpen}/>
    </Wrapper>
  )
}
