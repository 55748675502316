import styled from 'styled-components';

const StyleBase = styled.div``

const StyledContainerLeft = styled(StyleBase)<{isMobile?: boolean,isZc?: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'row-reverse' : 'row-reverse' };
    color: #FFFFFF;
    // justify-content: space-evenly;
    align-items: end;
    position: fixed;
    bottom: 37px;
    right: 19px;
    z-index: 999;    
    width: ${({isZc}) => isZc ?  '' : '29px' };
    overflow: hidden;
    animation: ${({isZc}) => isZc ?  'isZcAction-J-m 1.2s' : '' };
    animation-fill-mode: forwards;
    .zk {    
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 54px;
        margin-left: 6px; 
        img {
            position: relative;
            top: -2px; 
            margin-left: 3px;
            transform: ${({isZc}) => isZc ?  'rotateZ(180deg)' : '' }
        }
    }
    a {
        display: flex; 
        justify-content: center;
        align-items: center;
    }
    
    p {
        margin-left: 10px;
        height: 26px;
        width: 26px;
        margin-top: 12px;
    }
    p:hover {
        transform: scale(1.2);
        curosr: pointer;
    }
    .left_img_m_1 {
        background: url('/images/home/naio_ac.png');
        background-size: 100% 100%;
    }
    .left_img_m_1:hover {
        background: url('/images/home/naio_z1.png');
        background-size: 100% 100%;
    }
    .left_img_m_2 {
        background: url('/images/home/m.png');
        background-size: 100% 100%;
    }
    .left_img_m_2:hover {
        background: url('/images/home/m_z1.png');
        background-size: 100% 100%;
    }
    @keyframes isZcAction-J-m {
        0% {
            width: 29px;
        }
        100% {
            width: 101px;
        }
    }
    @keyframes isZcAction-C {
        0% {
            width: 101px;
        }
        100% {
            width: 29px;
        }
    }
    
    .active_left1 {
        top: 95px;
    }
    .active_left2 {
        top: calc(100vh+95px);
    }
    .active_left3 {
        top: calc(200vh+95px);
    }
    .active_left4 {
        top: calc(300vh+95px);
    }
    .active_left5 {
        top: calc(400vh+95px);
    }
    .active_left6 {
        top: calc(500vh+95px);
    }
`
const StyledContainerRight = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 446px;
    right: 52px;
    img {
        margin-top: 20px;
    }
`

const StyledContainer1 = styled(StyleBase)<{isMobile?: boolean }>`
    height: calc(852px - 67px);
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    transform: translateY(46px);
    -webkit-transform: translateY(46px);
`

const StyledContainer2 = styled(StyleBase)<{isMobile?: boolean }>`    
    padding: 80px  0;
    padding-bottom: ${({isMobile}) => isMobile ?  '' : '120px' };
    height: ${({isMobile}) => isMobile ?  '500px' : '' };
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    // background: #FFFFFF;
    color: #1673FF;
    justify-content: center;
    align-items: center;
    background: url('/images/home/mbj_2.png');
    background-size: 100% 100%;
    .cardRight {
        width: 245px;
        height: 245px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: ${({isMobile}) => isMobile ?  'translateY(146px) translateX(-25vw)' : '' };
        img {
            width: 126px;
        }
        .img1 {
            position: absolute;
            z-index: 2;
        }
        .img2 {
            animation: McardPeotect1_1 2s;
            animation-fill-mode: forwards;
            transform-origin: 62px 257px;
            z-index: 1;
        }
        .img3 {
            position: absolute;
            animation: McardPeotect3_1 2s;
            animation-fill-mode: forwards;
            transform-origin: 146px 290px;
            z-index: 3;
        }
    }
    .cardRight-c {
        width: 245px;
        height: 245px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: ${({isMobile}) => isMobile ?  'translateY(146px) translateX(-25vw)' : '' };
        img {
            width: 126px;
        }
        .img1 {
            position: absolute;
            z-index: 2;
        }
        .img2 {
            animation: McardPeotect1_1-C 1.5s;
            animation-fill-mode: forwards;
            transform-origin: 62px 257px;
            z-index: 1;
        }
        .img3 {
            position: absolute;
            animation: McardPeotect3_1-C 1.5s;
            animation-fill-mode: forwards;
            transform-origin: 146px 290px;
            z-index: 3;
        }
    }
    @keyframes McardPeotect1_1 {
        0% {
            transform: translateX(0px) translateY(0px) rotate(0deg);
        }
        100% {
            transform: translateX(-95px) translateY(-47px) rotate(0deg);
        }
    }
    @keyframes McardPeotect3_1 {
        0% {
            transform: translateX(0px) translateY(0px) rotate(0deg);
        }
        100% {
            transform: translateX(95px) translateY(-24px) rotate(0deg);
        }
    }
    
    @keyframes McardPeotect1_1-C {
        0% {
            transform: translateX(-95px) translateY(-47px) rotate(0deg);
        }
        100% {
            transform: translateX(0px) translateY(0px) rotate(0deg);
        }
    }
    @keyframes McardPeotect3_1-C {
        0% {
            transform: translateX(95px) translateY(-24px) rotate(0deg);
        }
        100% {
            transform: translateX(0px) translateY(0px) rotate(0deg);
        }
    }
    .Container2Left {
        .aegis {
            color: #fff;
            font-size: 30px;
            font-family: Arial-Bold, Arial;
            font-weight: bold;
            margin-top: 85px;
        }
        .browser {
            margin-top: 15px;
            width: 339px;
            font-size: 16px;
            font-family: Courier New-Regular,Courier New;
            font-weight: 400;
            color: #E1E1E1;
        }
        .buttonJon {
            display: flex;
            .join {
                width: 207px;
                height: 45px;
                background: #9333EA;
                border-radius: 6px 6px 6px 6px;
                opacity: 1;
                font-size: 12px;
                font-family: Copperplate-Bold-x;
                font-weight: bold;
                color: #FFFFFF;
                text-align: center;
                line-height: 45px;
                margin-top: 20px;
            }
            .join:hover {
              color: #FEFDFE;
              background: #B360FF;
            }
            .joinImg {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                top: 12px;
                .img1 {
                    margin-left: 16px;
                    width: 21.56px;
                    height: 21.56px;
                }
                .img2 {
                    margin-left: 7px;
                    width: 21.56px;
                    height: 21.56px;
                }
                .img3 {
                    margin-left: 7px;
                    width: 21.56px;
                    height: 21.56px;
                }
            }
        }
    }
    .Container2Left-j-1 {
        animation: container2Left-J-m-1 1.5s;
        animation-fill-mode: forwards;
    } 
    .Container2Left-j-2 {
        animation: container2Left-J-m-2 1.5s;
        animation-fill-mode: forwards;
    } 
    .Container2Left-c-1 {
        animation: container2Left-C-m-1 1.5s;
        animation-fill-mode: forwards;
    }
    .Container2Left-c-2 {
        animation: container2Left-C-m-2 1.5s;
        animation-fill-mode: forwards;
    }
    
    @keyframes container2Left-J-m-1 {
        0% {
            opacity: 0;
            transform: translateX(150px);
        }
        100% {
            opacity: 1;
            transform: translateX(2%);
        }
    }    
    @keyframes container2Left-J-m-2 {
        0% {
            opacity: 0;
            transform: translateX(43vw) translateY(-145px);
        }
        100% {
            opacity: 1;
            transform: translateX(23vw) translateY(-145px);
        }
    }     
    @keyframes container2Left-C-m-1 {
        0% {
            opacity: 1;
            transform: translateX(2%);
        }
        100% {
            opacity: 0;
            transform: translateX(150px);
        }
    }    
    @keyframes container2Left-C-m-2 {
        0% {
            opacity: 1;
            transform: translateX(23vw) translateY(-145px);
        }
        100% {
            opacity: 0;
            transform: translateX(43vw) translateY(-145px);
        }
    }
`

const StyledContainer3 = styled(StyleBase)<{isMobile?: boolean }>`
    padding: 100px  0;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    background: url('/images/home/mbj_3.png');
    background-size: 100% 100%;
    height:  ${({isMobile}) => isMobile ?  '500px' : '' };
    .Container3Right {
        width: 245px;
        height: 245px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: ${({isMobile}) => isMobile ?  'translateY(90px) translateX(20vw)' : '' };
        img {
            width: 126px;
        }
        .img1 {position: absolute;
            z-index: 2;
        }
        .img2 {
            animation: cardPeotect1 2s;
            animation-fill-mode: forwards;
            transform-origin: 62px 257px;
            z-index: 1;
        }
        .img3 {
            position: absolute;
            animation: cardPeotect3 2s;
            animation-fill-mode: forwards;
            transform-origin: 45px 233px;
            z-index: 3;
        }
    }
    
    .Container3Right-C {
        width: 245px;
        height: 245px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: ${({isMobile}) => isMobile ?  'translateY(90px) translateX(20vw)' : '' };
        img {
            width: 126px;
        }
        .img1 {position: absolute;
            z-index: 2;
        }
        .img2 {
            animation: cardPeotect1-C 2s;
            animation-fill-mode: forwards;
            transform-origin: 62px 257px;
            z-index: 1;
        }
        .img3 {
            position: absolute;
            animation: cardPeotect3-C 2s;
            animation-fill-mode: forwards;
            transform-origin: 45px 233px;
            z-index: 3;
        }
    }
    @keyframes cardPeotect1 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-20deg);
        }
    }
    @keyframes cardPeotect3 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(20deg);
        }
    }
    @keyframes cardPeotect1-C {
        0% {
            transform: rotate(-20deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    @keyframes cardPeotect3-C {
        0% {
            transform: rotate(20deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    .Container3Left {
        .aegis {
            font-size: 30px;
            font-family: Arial-Bold, Arial;
            font-weight: bold;
            margin-top: 115px;
        }
        .a {
            margin-top: 15px;
            width: 339px;
            font-size: 16px;
            font-family: Courier New-Regular,Courier New;
            font-weight: 400;
            color: #E1E1E1;
        }
    }
    .Container3Left-j-1 {
        animation: container3Left-J-m-1 1.5s;
        animation-fill-mode: forwards;
    }
    .Container3Left-j-2 {
        animation: container3Left-J-m-2 1.5s;
        animation-fill-mode: forwards;
    }
    
    .Container3Left-c-1 {
        animation: container3Left-C-m-1 1.53s;
        animation-fill-mode: forwards;
    }
    .Container3Left-c-2 {
        animation: container3Left-C-m-2 1.53s;
        animation-fill-mode: forwards;
    }
    
    @keyframes container3Left-J-m-1 {
        0% {
            opacity: 0;
            transform: translateX(-150px);
        }
        100% {
            opacity: 1;
            transform: translateX(9%);
        }
    }    
    
    @keyframes container3Left-J-m-2 {
        0% {
            opacity: 0;
            transform: translateX(-40vw) translateY(-171px);
        }
        100% {
            opacity: 1;
            transform: translateX(-20vw) translateY(-171px);
        }
    } 
    @keyframes container3Left-C-m-1 {
        0% {
            opacity: 1;
            transform: translateX(9%);
        }
        100% {
            opacity: 0;
            transform: translateX(-150px);
        }
    }
    @keyframes container3Left-C-m-2 {
        0% {
            opacity: 1;
            transform: translateX(-20vw) translateY(-171px);
        }
        100% {
            opacity: 0;
            transform: translateX(-40vw) translateY(-171px);
        }
    }
`

const StyledContainer4 = styled(StyleBase)<{isMobile?: boolean }>`
    height: ${({isMobile}) => isMobile ?  '600px' : '500px' };
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'row' : 'row' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    background: url('/images/home/mbj_3.png');
    background-size: 100% 100%;
    .img-box {
        display: inline-block;
        width: 40px;
        height: 34px;
        border-radius: 8px;
        overflow: hidden;
    }
    .boxCircle {
        width: 150px;
        height: 150px;
        position: relative;
        transform: ${({isMobile}) => isMobile ?  'scale(1.2) translateY(-32px)' : '' };
        >div {
            width: 150px;
            height: 150px;
            background: #9333EA;
            // border: 3px solid #9EF3F5;
            border-radius: 198px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .circle {
            font-size: 68px;
            font-family: Copperplate-Bold-x;
            font-weight: bold;
            color: #fff;
            z-index: 2;
        }
        .circle1 {
            animation: circle1 3s;
            animation-iteration-count: infinite;
        }
        
        .circle2 {
            animation: circle1 6s;
            animation-iteration-count: infinite;
        }
        .circle3 {
            animation: circle1 9s;
            animation-iteration-count: infinite;
        }
        .circle4 {
            animation: circle1 12s;
            animation-iteration-count: infinite;
        }
        
        .circle5 {
            background: rgba(147,51,234,0.7);
            border: 3px solid #D3A8FB;
            z-index: 3;
            flex-wrap: wrap;
            font-size: 12px;    
            justify-content: space-evenly;
            align-items: center;
            img {
                width: 30px;
            }
            >div {
                display: flex;
                >div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-top: 3px;
                    >div {
                        margin-top: 3px;
                    }
                }
            }
        }
        .circle5-j {
            animation: circle5-J-m 2s;
            animation-fill-mode: forwards;
        }
        .circle5-c {
            animation: circle5-C-m 0.5s;
            animation-fill-mode: forwards;
        }
        .circle6 {
            background: rgba(147,51,234,0.7);
            border: 3px solid #D3A8FB;
            z-index: 3;
            img {
                width: 40px;
                height: 34px;
            }
            .h_img1 {
                width: 102px;
                height: 87px;
                overflow: hidden;
                border-radius: 19px;
                color: #000;
                .h_img1_top {
                    height: 30px;
                    line-height: 30px;
                    background: #EBF1F7;
                    text-align: center;
                    color: #6E91EC;
                    font-weight: 800;
                }
                .h_img1_bom {
                    height: 57px;
                    background: #FEFEFE;
                    text-align: center;
                }
            }
        } 
        .circle6-j {
            animation: circle6-J-m 2s;
            animation-fill-mode: forwards;
        }
        .circle6-c {
            animation: circle6_6-C-m 0.5s;
            animation-fill-mode: forwards;
        }
        
        .circle7 {
            background: rgba(147,51,234,0.7);
            border: 3px solid #D3A8FB;
            z-index: 3;
            flex-direction: column;
            img {
                width: 15px;
                height: 15px;
            }
            >div {
                text-align: start;
                width: 76px;
                font-size: 12px;
                font-family: Copperplate-Bold, Copperplate;
                font-weight: bold;
                color: #FFFFFF;
                margin: 8px 0;   
                display: flex;
                justify-content: start;
                align-items: center;
                span {
                    margin-left: 12px;
                }
            }
        }
        .circle7-j {
            animation: circle7-J-m 2s;
            animation-fill-mode: forwards;
        }
        .circle7-c {
            animation: circle7-C-m 0.5s;
            animation-fill-mode: forwards;
        }
        
        @keyframes circle1 {
            0% {
                transform: scale(1);
                opacity: 0.5;
            }
            100% {
                transform: scale(2);
                opacity: 0;
            }
        }
        @keyframes circle5-J-m {
            0% {
                transform: translateX(85px) translateY(64px);
            }
            100% {
                transform: translateX(96px) translateY(93px);
            }
        }
        @keyframes circle5-C-m {
            0% {
                transform: translateX(96px) translateY(93px);
            }
            100% {
                transform: translateX(85px) translateY(64px);
            }
        }
        @keyframes circle6-J-m {
            0% {
                transform: translateX(-70px) translateY(73px);
            }
            100% {
                transform: translateX(-89px) translateY(95px);
            }
        }
        @keyframes circle6_6-C-m {
            0% {
                transform: translateX(-89px) translateY(95px);
            }
            100% {
                transform: translateX(-70px) translateY(73px);
            }
        }
        @keyframes circle7-J-m {
            0% {
                transform: translateX(0px) translateY(-65px);
            }
            100% {
                transform: translateX(0px) translateY(-121px);
            }
        }
        @keyframes circle7-C-m {
            0% {
                transform: translateX(0px) translateY(-121px);
            }
            100% {
                transform: translateX(0px) translateY(-65px);
            }
        }
    }
`

const StyledContainer5 = styled(StyleBase)<{isMobile?: boolean }>`
    height: 700px;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    background: url('/images/home/mbj_4.png');
    background-size: 100% 100%;
    .Container5Box {
        position: absolute;
        width: 237px;
        height: 350px;
        box-sizing: border-box;
        
        .txt3 {    
            width: ${({isMobile}) => isMobile ?  '72vw' : '339px' };
            min-width: 339px;
            max-width: 650px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: start;
            margin-top: ${({isMobile}) => isMobile ?  '' : '42px' };
        }
        .txt3-j-1 {
            animation: txt3-J-m-1 3s;
            animation-fill-mode: forwards; 
        }
        
        .txt3-j-2 {
            animation: txt3-J-m-2 3s;
            animation-fill-mode: forwards; 
        }
        
        .txt3-c {
            animation: txt3-C-m 1.5s;
            animation-fill-mode: forwards; 
        }
        @keyframes txt3-J-m-1 {
            0% {
                opacity: 0;
                transform: translateX(-14%) translateY(318px);
            }
            100% {
                opacity: 1;
                transform: translateX(-14%) translateY(234px);
            }
        }
        @keyframes txt3-J-m-2 {
            0% {
                opacity: 0;
                transform: translateX(-20vw) translateY(339px);
            }
            100% {
                opacity: 1;
                transform: translateX(-20vw) translateY(339px);
            }
        }
        @keyframes txt3-C-m {
            0% {
                opacity: 1;
                transform: translateX(-14%) translateY(318px);
            }
            100% {
                opacity: 0;
                transform: translateX(-14%) translateY(318px);
            }
        }
        .circle {
            position: absolute;
            right: -57%;
            width: 103px;
            height: 130px;
            background: #4A1975;
            border: 3px solid #9333EA;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            z-index: 4;    
            font-family: Copperplate-Bold-x;
            font-size: 30px;
            font-weight: bold;
            color: #fff;
            z-index: 2;
            box-sizing: border-box;
            .security {
                height: 27px;
                font-size: 12px;
                font-family: Arial-Bold, Arial;
                font-weight: bold;
                color: #fff;    
                text-align: center;
                letter-spacing: 1px;    
                margin-top: 5px;
            }
        }
        .circle-j-1 {
            animation: circle-J-m-1 1.5s;
            animation-fill-mode: forwards; 
        }
        .circle-j-2 {
            animation: circle-J-m-2 1.5s;
            animation-fill-mode: forwards; 
        }
        
        .circle-c-1 {
            animation: circle-C-m-1 0.5s;
            animation-fill-mode: forwards; 
        }

        .circle-c-2 {
            animation: circle-C-m-2 0.5s;
            animation-fill-mode: forwards; 
        }
        @keyframes circle-J-m-1 {
            0% {
                transform: translateY(13%) translateX(-50%);
            }
            30% {
                transform: translateY(13%) translateX(-50%);
            }
            100% {
                transform: translateY(13%) translateX(-69%);
            }
        }
        @keyframes circle-J-m-2 {
            0% {
                transform: translateY(16%) translateX(-26%) scale(1.2);
            }
            30% {
                transform: translateY(16%) translateX(-26%) scale(1.2);
            }
            100% {
                transform: translateY(16%) translateX(-43%) scale(1.2);
            }
        }
        @keyframes circle-C-m-1 {
            0% {
                opacity: 1;
                transform: translateY(13%) translateX(-69%);
            }
            100% {
                opacity: 0;
                transform: translateY(13%) translateX(-50%);
            }
        }
        @keyframes circle-C-m-2 {
            0% {
                opacity: 1;
                transform: translateY(16%) translateX(-43%) scale(1.2);
            }
            100% {
                opacity: 0;
                transform: translateY(16%) translateX(-26%) scale(1.2);
            }
        }
        .ellipse {
            width: ${({isMobile}) => isMobile ?  '150px' : '125px' };
            height: ${({isMobile}) => isMobile ?  '318px' : '265px' };
            border: 3px dashed #9333EA;
            border-radius: 100%;
            position: absolute;
        }
        .ellipse-j-1 {
            animation: ellipse-J-m-1 1.5s;
            animation-fill-mode: forwards; 
        }
        .ellipse-j-2 {
            animation: ellipse-J-m-2 1.5s;
            animation-fill-mode: forwards; 
            // transform: translateX(135px) translateY(-74px) rotate(0deg);
        }
        .ellipse-c-1 {
            animation: ellipse-C-m-1 0.5s;
            animation-fill-mode: forwards;
        }
        .ellipse-c-2 {
            animation: ellipse-C-m-2 0.5s;
            animation-fill-mode: forwards;
        }
        @keyframes ellipse-J-m-1 {
            0% {
                opacity: 0;
                transform: translateX(100px) translateY(-20%);
            }
            100% {
                opacity: 1;
                transform:  translateX(108%) translateY(-20%) rotate(0deg);
            }
        }
        @keyframes ellipse-J-m-2 {
            0% {
                opacity: 0;
                transform: translateX(5px) translateY(-74px) rotate(0deg);
            }
            100% {
                opacity: 1;
                transform: translateX(135px) translateY(-74px) rotate(0deg);
            }
        }
        @keyframes ellipse-C-m-1 {
            0% {
                opacity: 1;
                transform:  translateX(108%) translateY(-20%) rotate(0deg);
            }
            100% {
                opacity: 0;
                transform: translateX(100px) translateY(-20%);
            }
        }

        @keyframes ellipse-C-m-2 {
            0% {
                opacity: 1;
                transform: translateX(81%) translateY(-23%);
            }
            100% {
                opacity: 0;
                transform: translateX(91%) translateY(-23%);
            }
        }
        .fourCircleBox {
            position: absolute;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            z-index: 2;
            text-align: end;
            .circleX {
                padding: 1px;
                background: #000F19;
                border: 2px solid #9333EA;
                border-radius: 60%;
                display: flex;
                justify-content: center;
                align-items: center;
                >div {
                    width: 28px;
                    height: 28px;
                    background: #000F19;
                    border-radius: 60%;
                }
            }
            .txt1 {
                margin-top: -14px;
                font-size: ${({isMobile}) => isMobile ?  '18px' : '16px' };
                // font-family: Copperplate-Bold, Copperplate;
                font-weight: bold;
                color: #FFFFFF;
                padding-right: 12px;
            }
            .txt2 {    
                margin-top: 4px;
                font-size: ${({isMobile}) => isMobile ?  '14px' : '12px' };
                // font-family: Courier New-Regular,Courier New;
                font-weight: 400;
                color: #FFFFFF;
                margin-right: 12px;
            }
            .txt2_1 {
                width: ${({isMobile}) => isMobile ?  '182px' : '156px' };
            }
            .txt2_2 {
                width: ${({isMobile}) => isMobile ?  '182px' : '155px' };
            }
            .txt2_4 {
                width: ;
                width: ${({isMobile}) => isMobile ?  '248px' : '208px' };
            }
        }
        .fourCircleBoxHover {
            .circleX {
                >div {
                    background: #9333EA;
                }
            }
            .txt1 {
                color: #9333EA;
            }
        }
        .transformBox1 {
            transform: ${({isMobile}) => isMobile ?  'translateX(-208px) translateY(76px)' : 'translateX(-183px) translateY(71px)' };
            .circleX {
                >div {
                    // background: #1673FF;
                }
            }
            .txt1 {
                // color: #1673FF;
            }
        }
        .transformBox2 {
            transform: ${({isMobile}) => isMobile ?  'translateX(-165px) translateY(-13px)' : 'translateX(-147px) translateY(-13px)' };
            .circleX {
                >div {
                    // background: #1673FF;
                }
            }
            .txt1 {
                // color: #1673FF;
            }
        }
        
        .transformBox3 {
            transform: ${({isMobile}) => isMobile ?  'translateX(-241px) translateY(275px)' : 'translateX(-198px) translateY(237px)' };
            .circleX {
                >div {
                    // background: #1673FF;
                }
            }
            .txt1 {
                // color: #1673FF;
            }
        }

        .transformBox4 {
            transform: ${({isMobile}) => isMobile ?  'translateX(-127px) translateY(177px)' : 'translateX(-105px) translateY(152px)' };
            .circleX {
                >div {
                    // background: #1673FF;
                }
            }
            .txt1 {
                // color: #1673FF;
            }
        }
    }
`

const StyledContainer6 = styled(StyleBase)<{isMobile?: boolean }>`
    height: ${({isMobile}) => isMobile ?  '650px' : '550px' };
    // padding-bottom: 50px;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    background: url('/images/home/mbj_5.png');
    background-size: 100% 100%;
    .Container7Box {
        position: absolute;
        transform: ${({isMobile}) => isMobile ?  'translateY(217px) translateX(-35px) scale(1)' : 'translateY(223px) translateX(-35px)' };
        .img1 {    
            width: 74px;
            height: 92px;
            position: absolute;
            top: -256px;
            z-index: 2;
            transform: ${({isMobile}) => isMobile ? 'scale(1.2)' : '' };
        }
        .txtBox {
            position: absolute;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: start;
            border-bottom: 1px solid #9333EA;
            padding-bottom: 10px;
            .txt1 {
                font-size: ${({isMobile}) => isMobile ? '20px' : '18px' };
                font-family: Copperplate-Bold, Copperplate;
                font-weight: bold;
                color: #FFFFFF;
            }
            .txt2 {
                font-size: ${({isMobile}) => isMobile ? '14px' : '12px' };
                // font-family: Courier New-Regular, Courier New;
                font-weight: 400;
                color: #FEFEFE;
                line-height: 16px;
                margin-top: 5px;
            }
            .line {
                position: absolute;
                height: 1px;
                background: #9333EA;
            }
        }

        .txtBox1 {
            width: ${({isMobile}) => isMobile ? '191px' : '166px' };
            transform: ${({isMobile}) => isMobile ? 'translateX(-184px) translateY(-463px)' : 'translateX(-154px) translateY(-454px)' };
            .line1 {
                width: 140px;
                transform: ${({isMobile}) => isMobile ? 'translateX(129px) translateY(109px) rotateZ(83deg)' : 'translateX(104px) translateY(109px) rotateZ(83deg)' };
            }
        }
        .box-txt-j {
            animation: box-txt-J-m 1.5s;
            animation-fill-mode: forwards;
        }
        .box-txt-c {
            animation: box-txt-C-m 1.5s;
            animation-fill-mode: forwards;
        }
        
        @keyframes box-txt-J-m {
            0% {
                opacity: 0;
                transform: translateY(-200px);
            }
            100% {
                opacity: 1;
                transform: translateY(0px);
            }
        } 
        @keyframes box-txt-C-m {
            0% {
                opacity: 1;
                transform: translateY(0px);
            }
            100% {
                opacity: 0;
                transform: translateY(-200px);
            }
        } 
        
        .txtBox2 {
            transform: ${({isMobile}) => isMobile ? 'translateX(-174px) translateY(-166px)' : 'translateX(-139px) translateY(-161px)' };
            .txt2 {
                width: ${({isMobile}) => isMobile ? '147px' : '125px' };
            }
            .line2 {
                width: 72px;
                transform: ${({isMobile}) => isMobile ? 'translateX(127px) translateY(-1px) rotateZ(-63deg)' : 'translateX(105px) translateY(-1px) rotateZ(-63deg)' };
            }
        }

        .txtBox3 {
            transform: ${({isMobile}) => isMobile ? 'translateX(90px) translateY(-389px)' : 'translateX(90px) translateY(-381px)' };
            .txt2 {
                width: ${({isMobile}) => isMobile ? '150px' : '124px' };
            }
            .line3 {
                width: 78px;
                transform: ${({isMobile}) => isMobile ? 'translateX(-58px) translateY(73px) rotateZ(-60deg)' : 'translateX(-58px) translateY(73px) rotateZ(-60deg)' };
            }
        }        
        .txtBox4 {
            transform: ${({isMobile}) => isMobile ? 'translateX(82px) translateY(-101px)' : 'translateX(82px) translateY(-104px)' };
            .txt1 {
                width: ${({isMobile}) => isMobile ? '173px' : '149px' };
            }
            .line4 {
                width: 123px;
                transform: ${({isMobile}) => isMobile ? 'translateX(-75px) translateY(-28px) rotateZ(77deg)' : 'translateX(-75px) translateY(-28px) rotateZ(77deg)' };
            }
        }
    }
    
    .circle6-j {
        animation: circle6-J6 1.5s;
        animation-fill-mode: forwards;
    }
    .circle6-c {
        animation: circle6-C 0.5s;
        animation-fill-mode: forwards;
    }
    
    @keyframes circle6-J6 {
        0% {
            opacity: 0;
        }
        20% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    } 
    @keyframes circle6-C {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    } 
`

const StyledContainer7 = styled(StyleBase)<{isMobile?: boolean }>`
    height: 200px;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;    
    background: linear-gradient(to right,#1C092D,#1D0A2F);
    .Container7Box {
        display: flex;
        flex-direction: column;
        position: relative;
        top: -11px;
        justify-content: space-between;
        .lmgbox {
            display: flex;
            justify-content: space-around;
            img {
                display: block;
                margin-bottom: 36px;
            }
            .img-box1,.img-box2,.img-box3,.img-box4 {
                position: absolute;
                top: 58px;
                width: 301px;
                height: 1px;
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #FFFFFF 52%, rgba(255,255,255,0) 100%); 
            }
            .img-box2 {
                top: 128px;
            }
            .img-box3 {
                top: 192px;
            }
            .img-box4 {
                transform: rotate(90deg);        
                top: 128px;
                width: 237px;
            }
        }
        .lmgboxp {
            width: 547px;
            >div {
                display: flex;
                justify-content: space-around;
                align-items: center;
            }
            img {
                display: block;
                margin-bottom: 36px;
            }
            .img-box1,.img-box2,.img-box3,.img-box4 {
                position: absolute;
                top: 58px;
                width: 547px;
                height: 1px;
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #FFFFFF 52%, rgba(255,255,255,0) 100%); 
            }
            .img-box2 {
                top: 128px;
            }
            .img-box3 {
                transform: rotate(90deg);        
                top: 90px;
                left: 69px;
                width: 201px;
            }
            .img-box4 {
                transform: rotate(90deg);        
                top: 90px;
                left: 264px;
                width: 201px;
            }
        }
        .footer {
            width: ${({isMobile}) => isMobile ?  '' : '' };
            display: flex;
            flex-direction: ${({isMobile}) => isMobile ?  '' : 'column' };
            align-items: ${({isMobile}) => isMobile ?  '' : '' };
            // margin-top: 83px;    
            word-spacing: -3px;
            .footerTop {
                margin-top: 90px;
                color: #9333EA;
                font-size: 14px;
                display: flex;
                justify-content: space-between;    
                margin-bottom: 76px;
                a {
                    color: #9333EA;  
                    text-decoration:none
                }
            }
            .footerLeft {
                margin-top: 20px;
                margin-right: 20px;
                >div {
                    font-size: 12px;
                    font-family: Courier New-Regular, Courier New;
                    font-weight: 400;
                    color: #FFFFFF;
                    >span {
                        width: 127px;    
                        font-size: 16px;
                        font-family: Copperplate-Bold-x;
                        font-weight: bold;
                        color: #FFFFFF;
                        margin-left: 16px;
                    }
                }
            }
            .footerRight {
                margin-top: ${({isMobile}) => isMobile ?  '56px' : '' };
                >div {
                    font-size: 12px;
                    font-family: Courier New-Regular, Courier New;
                    font-weight: 400;
                    color: #FFFFFF;
                    >span {
                        margin-top: 30px;
                        font-size: 12px;
                        margin-right: 6px;
                        font-weight: 800;
                        a {
                            color: #FFFFFF;
                            text-decoration:none;
                        }
                    }
                }
            }
        }
    }
`

// eslint-disable-next-line import/prefer-default-export
export { StyledContainerLeft,StyledContainerRight, StyledContainer1, StyledContainer2, StyledContainer3, StyledContainer4, StyledContainer5, StyledContainer6, StyledContainer7 }