/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, useState, useEffect } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import useEagerConnect from 'hooks/useEagerConnect'
import history from './routerHistory'
import Home from './views/Home'
import PrivacyPolicy from './views/PrivacyPolicy'
import TermsOfService from './views/TermsOfService'
import Web3Form from './views/Web3Form'
import 'antd/dist/reset.css'
import './font.css'
import './globle.css'




const App: React.FC = () => {
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1300px)').matches,
  );
  const [matches1590, setMatches1590] = useState(
    window.matchMedia('(min-width: 1590px)').matches,
  );

  useEffect(() => {
    document.title = 'AEGIS - Your AI Security Guard of Web3'
    window
      .matchMedia('(min-width: 1300px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
    
    window
    .matchMedia('(min-width: 1590px)')
    .addEventListener('change', (e) => {
      setMatches1590(e.matches)
    });
    
    // 判断当前移动端设备
    // const u = navigator.userAgent;
    // const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
    // const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    // if (isAndroid) {
    //     // 安卓手机
    //     alert('安卓手机')
    // }
    // if (isIOS) {
    //     // ios手机
    //     alert('ios手机')
    // }
  }, []);
  
  useEagerConnect()
  return (
    <div style={{background: '#000F19', minWidth: matches ? '100vw' : '100vw', minHeight: '100vh'}}>
      <div style={{width: 0, height: 0, overflow: 'hidden'}}>
        <h1>Aegis</h1>
        <h2>Security</h2>
        <h2>Wallet</h2>
        <h2>Simulation</h2>
      </div>
      <Router history={history}>
        <Switch>
            {/* <Redirect exact from="/" to="/home" /> */}
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/PrivacyPolicy">
              <PrivacyPolicy />
            </Route>
            <Route path="/TermsOfService">
              <TermsOfService />
            </Route>
          </Switch>
      </Router>
    </div>
  )
}

export default React.memo(App)

