/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import Web3Form from 'views/Web3Form';
import './index.css'

export default function index(props: any) {

  return (
    <>
      <Modal footer={null} wrapClassName='web3formbox' width={886} open={props?.web3FormIsModalOpen} onCancel={() => { props?.setWeb3FormIsModalOpen(false) }}>
        <Web3Form FnOK={props?.setWeb3FormIsModalOpen} />
      </Modal>
    </>
  );
}
