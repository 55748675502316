/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components'; 
import { message } from 'antd';
import { useWeb3React } from '@web3-react/core';


const StyleBase = styled.div<{isMobile?: boolean }>`
  height: 626px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({isMobile}) => isMobile ?  '14px' : '13px' };
  .itemBox {
    margin-bottom: ${({isMobile}) => isMobile ?  '45px' : '45px' };
    .formTitle {
      >span {
        color: #9333EA;
      }
    }
    .none {
      height: 0px;
      width: 0px;
      border-bottom: none;
      padding-left: 0;
    }
    input {
      outline: none;
      border: none;
      background: rgba(0,0,0,0);
      border-bottom: 1px solid #9333EA;
      width: ${({isMobile}) => isMobile ?  '373px' : '90vw' };
      height: 27px;
      margin-top: 15px;
      padding-left: 15px;
      color: #B06CEE;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
    input::-webkit-input-placeholder { color: rgba(176,108,238,.7); }
    input:-moz-placeholder { color: rgba(176,108,238,.7); }
    input::-moz-placeholder { color: rgba(176,108,238,.7); }
    input:-ms-input-placeholder { color: rgba(176,108,238,.7); }
  }
  .itemFooter {
    display: flex;  
    button {
      display: flex;    
      width: 69px;
      height: 40px;
      border-radius: 8px;
      background: #9333EA;
      justify-content: space-evenly;
      align-items: center;
      margin-right: 20px;
      border: none;
      img {
        width: 14px;
      }
    }
    >div {
      line-height: 40px;
    }
  }
`

function App(porps: any) {
  const { account } = useWeb3React()
  const [newAccount,setNewAccount] = useState(account)
  const formRef = useRef(null)
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 400px)').matches,
  )

  useEffect(() => { 
    window
      .matchMedia('(min-width: 400px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, [])

  useEffect(() => {
    if(account){
      setNewAccount(account)
    }
  }, [account])

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "beb5a3be-a928-45e3-99a6-8db0b844ed5b");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      message.success('Email sent successfully!')
      formRef?.current?.reset()
    }
  };

  return (
    <StyleBase isMobile={matches}>
      <div>
        <form ref={formRef} onSubmit={onSubmit}>
          <div className='itemBox'>
            <div className='formTitle'>
              <span>1. </span> User Wallet Address.  <span>*</span>
            </div>
            <div className='formInput'>
              <input disabled value={newAccount} type="text" placeholder="Wallet address"/>
              <input className='none' name="address" value={newAccount} type="text" placeholder="Wallet address"/>
            </div>
          </div>
          <div className='itemBox'>
            <div className='formTitle'>
              <span>2. </span> Your name.  <span>*</span>
            </div>
            <div className='formInput'>
              <input type="text" name="name" placeholder="Type your answer here..."/>
            </div>
          </div>
          <div className='itemBox'>
            <div className='formTitle'>
              <span>3. </span> How should we get in touch when we launch?  <span>*</span>
            </div>
            <div className='formInput'>
              <input  type="email" name="email" placeholder="name@example.com"/>
            </div>
          </div>
          <div className='itemFooter'>
            <button type='submit' className='ok corsor-pointer' onClick={() => { 
              porps?.FnOK(false)
             }}>
              <span>OK</span><img src="/images/home/ok.svg" alt="" />
            </button> 
          </div>
        </form>
      </div>
    </StyleBase>
  );
}

export default App;