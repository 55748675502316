/* eslint-disable import/prefer-default-export */
export const interceptAdd = (add) => {
  const l = 4;
  return add
    ? `${add.substr(0, l)
    }...${add.substring(add.length - l)}`
    : 'Connect Wallet';
};

function IsPC() {
 
  const userAgentInfo = navigator.userAgent;
   
  const Agents = ["Android", "iPhone",
   
  "SymbianOS", "Windows Phone",
   
  "iPad", "iPod"];
   
  let flagPc = true;
   
  for (let v = 0; v < Agents.length; v++) {
   
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flagPc = false;
      
      break;
    
    }
   
  }
   
  return flagPc;
   
  }
   
   
   
  export const flagZt = IsPC();
   
  // console.log(flag) //true为PC端，false为手机端