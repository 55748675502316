/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

const StyleBase = styled.div``

const RidingLantern = styled(StyleBase)<{isMobile?: boolean,StyledContainer1Ref?: any,StyledContainer2Ref?: any,StyledContainer3Ref?: any,StyledContainer4Ref?: any,StyledContainer5Ref?: any,StyledContainer6Ref?: any,StyledContainer7Ref?: any }>`
    position: absolute;
    min-width: ${({isMobile,StyledContainer1Ref}) => isMobile ? ((!!StyledContainer1Ref?.current?.offsetWidth ? StyledContainer1Ref?.current?.offsetWidth : 1980) > 1590 ? '100vw' : '1590px') : '100vw' };        
    >div {
        color: #FFFFFF;
        justify-content: center;
        position: absolute;
        right: 52px;
        transition: top 1s;
        p {
            margin-top: 15px;
            width: 8px;
            height: 8px;
            border: 2px solid;
            border-radius: 11px;
        }
        .active_p {
            background: #9333EA;
            border: 2px solid #9333EA;
            height: 30px;
        }
    }
    .active-hover {
        display: flex;
        justify-content: end;
        align-items: center;
        div {
            width: 102px;
            position: absolute;
            display: flex;
            justify-content: end;
            opacity: 0;
        }
    }
    .active-hover:hover {
        
        border: 2px solid #9333EA;
        transform:scale(1.5);
        div {
            animation: active-hover-J 0.5s;
            animation-fill-mode: forwards;
            color: #9333EA;
        }
    }
    
    @keyframes active-hover-J {
        0% {
            opacity: 0;
            transform: translateX(33px);
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
            transform: translateX(-10px);
        }
    }
    .active_p1 {
        top: calc(449.5px);
        top: ${({StyledContainer1Ref}) => {
            return `calc(${ StyledContainer1Ref?.current?.offsetHeight/2 - 130 }px)`
          } };
    }
`


const StyledContainerLeft = styled(StyleBase)<{isMobile?: boolean,StyledContainer1Ref?: any,StyledContainer2Ref?: any,StyledContainer3Ref?: any,StyledContainer4Ref?: any,StyledContainer5Ref?: any,StyledContainer6Ref?: any,StyledContainer7Ref?: any }>`
    >div {
        display: flex;
        flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
        color: #FFFFFF;
        justify-content: center;
        align-items: center;
        position: fixed;
        transition: top 1s;
        .active_left1 {
            top: ${({StyledContainer1Ref}) => {
                return `calc(${ StyledContainer1Ref?.current?.offsetHeight/2 - 130 }px)`
              } };
        }
        left: 48px;
        p {
            margin-top: 10px;
            height: 37px;
            width: 37px;
        }
        p:hover {
            transform: scale(1.2);
            curosr: pointer;
        }
        .left_img1 {
            background: url('/images/home/naio_ac.png');
        }
        .left_img1:hover {
            background: url('/images/home/naio_z1.png');
            background-size: 100% 100%;
        }
        .left_img2 {
            background: url('/images/home/m.png');
        }
        .left_img2:hover {
            background: url('/images/home/m_z1.png');
            background-size: 100% 100%;
        }
        .left_img3 {
            background: url('/images/home/niao2.png');
        }
        .left_img3:hover {
            background: url('/images/home/niao2_z.png');
            background-size: 100% 100%;
        }
    }
    .active_left1 {
        top: ${({StyledContainer1Ref}) => {
            return `calc(${ StyledContainer1Ref?.current?.offsetHeight/2 - 130 }px)`
          } };
    }
`

const StyledContainer1 = styled(StyleBase)<{isMobile?: boolean }>`
    height: ${({isMobile}) => isMobile ?  '800px' : '800px' };
    min-height: 700px;
    // min-width: 1475px;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'row' : 'row' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    background: url('/images/home/home_bg1.png');
    background-size: 100% 100%;
`

const StyledContainer2 = styled(StyleBase)<{isMobile?: boolean }>`
    height: ${({isMobile}) => isMobile ?  '900px' : '700px' };
    min-height: 700px;
    // min-width: 1475px;
    box-sizing: border-box;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'row' : 'row' };
    background: #000F19;
    color: #1673FF;
    justify-content: space-evenly;
    align-items: center;
    background: url('/images/home/home_bg2.png');
    background-size: 100% 100%;
    .cardRight {
        width: 865.72px;
        height: 569.72px;
        padding-left: ${({isMobile}) => isMobile ? '52px' : '0px' };
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: ${({isMobile}) => isMobile ? '0px' : '0px' };
        transform: ${({isMobile}) => isMobile ? 'translateY(66px)' : 'translateX(0.3vw) translateY(66px) scale(0.7)' };
        img {
            width: 316px;
        }
        .img1 {
            position: absolute;
            z-index: 2;   
        }
        .img2 {
            animation: cardPeotect1_1 2s;
            animation-fill-mode: forwards;
            transform-origin: 117px 525px;
            z-index: 1;
        }
        .img3 {
            position: absolute;
            animation: cardPeotect3_1 2s;
            animation-fill-mode: forwards;
            transform-origin: 117px 525px;
            z-index: 3;
        }
    }
    .cardRight-c {
        width: 569.72px;
        height: 569.72px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: ${({isMobile}) => isMobile ? '0px' : '159px' };
        transform: ${({isMobile}) => isMobile ? 'translateY(66px)' : 'translateX(0.3vw) translateY(66px) scale(0.7)' };
        img {
            width: 315px;
            height: 505px;
        }
        .img1 {
            position: absolute;
            z-index: 2;
        }
        .img2 {
            animation: cardPeotect1_1-C 1.5s;
            animation-fill-mode: forwards;
            transform-origin: 117px 525px;
            z-index: 1;
        }
        .img3 {
            position: absolute;
            animation: cardPeotect3_1-C 1.5s;
            animation-fill-mode: forwards;
            transform-origin: 117px 525px;
            z-index: 3;
        }
    }
    @keyframes cardPeotect1_1 {
        0% {
            transform: translateX(0px) translateY(0px) rotate(0deg);
        }
        100% {
            transform: translateX(-238px) translateY(-118px) rotate(0deg);
        }
    }
    @keyframes cardPeotect3_1 {
        0% {
            transform: translateX(0px) translateY(0px) rotate(0deg);
        }
        100% {
            transform: translateX(237px) translateY(-59px) rotate(0deg);
        }
    }
    
    @keyframes cardPeotect1_1-C {
        0% {
            transform: translateX(-238px) translateY(-118px) rotate(0deg);
        }
        100% {
            transform: translateX(0px) translateY(0px) rotate(0deg);
        }
    }
    @keyframes cardPeotect3_1-C {
        0% {
            transform: translateX(237px) translateY(-59px) rotate(0deg);
        }
        100% {
            transform: translateX(0px) translateY(0px) rotate(0deg);
        }
    }
    .Container2Left {
        margin-left: ${({isMobile}) => isMobile ?  '0px' : '0px' };
        .aegis {
            height: 67px;
            font-size: 60px;
            font-family: Arial-Bold, Arial;
            font-weight: bold;
        }
        .browser {
            margin-top: 30px;
            max-width: 30vw;
            max-width: 503px;
            height: 59px;
            font-size: 18px;
            font-family: Courier New-Regular,Courier New;
            font-weight: 400;
            color: #E1E1E1;
        }
        .buttonJon {
            display: flex;
            .join {
                width: 263px;
                height: 58px;
                background: #9333EA;
                border-radius: 6px 6px 6px 6px;
                opacity: 1;
                font-size: 14px;
                font-family: Copperplate-Bold-x;
                font-weight: bold;
                color: #fff;
                text-align: center;
                line-height: 58px;
                margin-top: 39px;
            }
            .join:hover {
              color: #FEFDFE;
              background: #B360FF;
            }
            .joinImg {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                top: 20px;
                .img1 {
                    margin-left: 33px;
                    width: 42.67px;
                    height: 42.56px;
                }
                .img2 {
                    margin-left: 15px;
                    width: 42.67px;
                    height: 42.56px;
                }
                .img3 {
                    margin-left: 15px;
                    width: 42.67px;
                    height: 42.56px;
                }
            }
        }
    }
    .Container2Left-j {
        animation: container2Left-J-b 3s;
        animation-fill-mode: forwards;
    } 
    .Container2Left-c {
        animation: container2Left-C-b 1.5s;
        animation-fill-mode: forwards;
    }
    
    @keyframes container2Left-J-b {
        0% {
            opacity: 0;
            transform: ${({isMobile}) => isMobile ?  'translateY(33px) translateX(100px) scale(1)' : 'translateY(33px) translateX(100px) scale(1)' };
        }
        100% {
            opacity: 1;
            transform: ${({isMobile}) => isMobile ?  'translateY(33px) translateX(0) scale(1)' : 'translateY(33px) translateX(-3vw) scale(1)' };
        }
    }    
    @keyframes container2Left-C-b {
        0% {
            opacity: 1;
            transform: ${({isMobile}) => isMobile ?  'translateY(33px) translateX(0) scale(1)' : 'translateY(33px) translateX(-3vw) scale(1)' };
        }
        100% {
            opacity: 0;
            transform: ${({isMobile}) => isMobile ?  'translateY(33px) translateX(100px) scale(1)' : 'translateY(33px) translateX(100px) scale(1)' };
        }
    }
`

const StyledContainer3 = styled(StyleBase)<{isMobile?: boolean }>`
    height: ${({isMobile}) => isMobile ?  '900px' : '700px' };
    min-height: 700px;
    // min-width: 1475px;
    box-sizing: border-box;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'row' : 'row' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    background: url('/images/home/home_bg3.png');
    background-size: 100% 100%;
    .Container3Right {
        width: 30vw;
        height: 569.72px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: ${({isMobile}) => isMobile ? '' : 'scale(0.7)' };
        img {
            width: 316px;
            height: 528px;
        }
        .img1 {position: absolute;
            z-index: 2;
        }
        .img2 {
            animation: cardPeotect1 2s;
            animation-fill-mode: forwards;
            transform-origin: 117px 525px;
            z-index: 1;
        }
        .img3 {
            position: absolute;
            animation: cardPeotect3 2s;
            animation-fill-mode: forwards;
            transform-origin: 117px 525px;
            z-index: 3;
        }
    }
    
    .Container3Right-C {
        width: 569.72px;
        height: 569.72px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: ${({isMobile}) => isMobile ? '' : 'scale(0.7)' };
        img {
            width: 316px;
            height: 528px;
        }
        .img1 {position: absolute;
            z-index: 2;
        }
        .img2 {
            animation: cardPeotect1-C 2s;
            animation-fill-mode: forwards;
            transform-origin: 117px 525px;
            z-index: 1;
        }
        .img3 {
            position: absolute;
            animation: cardPeotect3-C 2s;
            animation-fill-mode: forwards;
            transform-origin: 117px 525px;
            z-index: 3;
        }
    }
    @keyframes cardPeotect1 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-20deg);
        }
    }
    @keyframes cardPeotect3 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(20deg);
        }
    }
    @keyframes cardPeotect1-C {
        0% {
            transform: rotate(-20deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    @keyframes cardPeotect3-C {
        0% {
            transform: rotate(20deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    .Container3Left {
        margin-right:  ${({isMobile}) => isMobile ?  '160px' : '80px' };
        .aegis {
            width: 387px;
            height: 67px;
            font-size: 60px;
            font-family: Arial-Bold, Arial;
            font-weight: bold;
        }
        .a {
            margin-top: 30px;
            width: 412px;
            height: 59px;
            font-size: 18px;
            font-family: Courier New-Regular,Courier New;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    .Container3Left-j {
        animation: container3Left-J 3s;
        animation-fill-mode: forwards;
    }
    
    .Container3Left-c {
        animation: container3Left-C 1.53s;
        animation-fill-mode: forwards;
    }
    
    @keyframes container3Left-J {
        0% {
            opacity: 0;
            transform: translateX(-300px);
        }
        100% {
            opacity: 1;
            transform: translateX(0deg);
        }
    }    
    @keyframes container3Left-C {
        0% {
            opacity: 1;
            transform: translateX(0deg);
        }
        100% {
            opacity: 0;
            transform: translateX(-300px);
        }
    }
`

const StyledContainer4 = styled(StyleBase)<{isMobile?: boolean }>`
    height: ${({isMobile}) => isMobile ?  '1083px' : '800px' };
    min-height: 700px;
    // min-width: 1475px;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'row' : 'row' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    background: url('/images/home/home_bg3.png');
    background-size: 100% 100%;
    .boxCircle {
        width: 396px;
        height: 396px;
        transform: ${({isMobile}) => isMobile ?  'scale(1) translateY(12%)' : 'scale(0.7) translateY(12%)' };
        >div {
            width: 396px;
            height: 396px;
            background: #9333EA;
            // border: 3px solid #9EF3F5;
            border-radius: 198px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .circle {
            font-size: 168px;
            font-family: Copperplate-Bold-x;
            font-weight: bold;
            color: #fff;
            z-index: 2;
        }
        .circle1 {
            animation: circle1 3s;
            animation-iteration-count: infinite;
        }
        
        .circle2 {
            animation: circle1 6s;
            animation-iteration-count: infinite;
        }
        .circle3 {
            animation: circle1 9s;
            animation-iteration-count: infinite;
        }
        .circle4 {
            animation: circle1 12s;
            animation-iteration-count: infinite;
        }
        
        .circle5 {
            background: rgba(147,51,234,0.7);
            border: 3px solid #D3A8FB;
            z-index: 3;
            flex-wrap: wrap;
            font-size: 16px;
            img {
                width: 50px;
                height: 50px;
            }
            >div {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                >div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-top: 3px;
                    >div {
                        margin-top: 10px;
                    }
                }
            }
        }
        .circle5-j {
            animation: circle5-J 2s;
            animation-fill-mode: forwards;
        }
        .circle5-c {
            animation: circle5-C 0.5s;
            animation-fill-mode: forwards;
        }
        .circle6 {
            background: rgba(147,51,234,0.7);
            border: 3px solid #D3A8FB;
            z-index: 3;
            img {    
                width: 100%;
                height: 57px;
            }
            .h_img1_box {
                display: flex;
            }
            .h_img1 {
                width: 102px;
                height: 87px;
                overflow: hidden;
                border-radius: 19px;
                color: #000;
                .h_img1_top {
                    height: 30px;
                    line-height: 30px;
                    background: #EBF1F7;
                    text-align: center;
                    color: #6E91EC;
                    font-weight: 800;
                }
                .h_img1_bom {
                    height: 57px;
                    background: #FEFEFE;
                    text-align: center;
                }
            }
        } 
        .circle6-j {
            animation: circle6-J 2s;
            animation-fill-mode: forwards;
        }
        .circle6-c {
            animation: circle6_6-C 0.5s;
            animation-fill-mode: forwards;
        }
        
        .circle7 {
            background: rgba(147,51,234,0.7);
            border: 3px solid #D3A8FB;
            z-index: 3;
            flex-direction: column;
            img {
                width: 27px;
                height: 27px;
            }
            .farst-letter:first-letter {
                font-weight: bold;
                font-size: 22px;
            }
            >div {
                text-align: start;
                width: 200px;
                font-size: 20px;
                font-family: Copperplate-Bold, Copperplate;
                color: #FFFFFF;
                line-height: 33px;    
                display: flex;
                justify-content: start;
                align-items: center;
                span {
                    margin-left: 12px;
                }
                &:nth-child(2) {
                    margin: 20px 0;
                }
            }
        }
        .circle7-j {
            animation: circle7-J 2s;
            animation-fill-mode: forwards;
        }
        .circle7-c {
            animation: circle7-C 0.5s;
            animation-fill-mode: forwards;
        }
        
        @keyframes circle1 {
            0% {
                transform: scale(1);
                opacity: 0.5;
            }
            100% {
                transform: scale(2);
                opacity: 0;
            }
        }
        @keyframes circle5-J {
            0% {
                transform: translateX(96px) translateY(93px);
            }
            100% {
                transform: translateX(260px) translateY(188px);
            }
        }
        @keyframes circle5-C {
            0% {
                transform: translateX(260px) translateY(188px);
            }
            100% {
                transform: translateX(96px) translateY(93px);
            }
        }
        @keyframes circle6-J {
            0% {
                transform: translateX(-89px) translateY(95px);
            }
            100% {
                transform: translateX(-259px) translateY(188px);
            }
        }
        @keyframes circle6_6-C {
            0% {
                transform: translateX(-259px) translateY(188px);
            }
            100% {
                transform: translateX(-89px) translateY(95px);
            }
        }
        @keyframes circle7-J {
            0% {
                transform: translateX(0px) translateY(-121px);
            }
            100% {
                transform: translateX(0px) translateY(-279px);
            }
        }
        @keyframes circle7-C {
            0% {
                transform: translateX(0px) translateY(-279px);
            }
            100% {
                transform: translateX(0px) translateY(-121px);
            }
        }
    }
`

const StyledContainer5 = styled(StyleBase)<{isMobile?: boolean }>`
    height: ${({isMobile}) => isMobile ?  '1083px' : '800px' };
    min-height: 700px;
    // min-width: 1475px;
    box-sizing: border-box;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'row' : 'row' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    background: url('/images/home/home_bg4.png');
    background-size: 100% 100%;
    .Container5Box {
        position: absolute;
        width: 474px;
        height: 474px;
        box-sizing: border-box;
        transform: ${({isMobile}) => isMobile ?  'scale(1)' : 'scale(0.7)' };
        .txt3 {
            width: 1280px;
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            transform: translateX(-326px) translateY(640px);
            font-family: Arial-Bold,Arial;
        }
        .txt3-j {
            animation: txt3-J 3s;
            animation-fill-mode: forwards; 
        }
        
        .txt3-c {
            animation: txt3-C 1.5s;
            animation-fill-mode: forwards; 
        }
        @keyframes txt3-J {
            0% {
                opacity: 0;
                transform: translateX(-420px) translateY(640px);
            }
            100% {
                opacity: 1;
                transform: translateX(-420px) translateY(540px);
            }
        }
        @keyframes txt3-C {
            0% {
                opacity: 1;
                transform: translateX(-420px) translateY(540px);
            }
            100% {
                opacity: 0;
                transform: translateX(-420px) translateY(640px);
            }
        }
        .circle {
            position: absolute;
            right: -52%;
            width: 402px;
            height: 402px;
            background: rgba(74,25,117,1);
            border: 10px solid #9333EA;
            border-radius: 205px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            z-index: 4;
            font-size: 139px;
            font-weight: bold;
            color: #FFF;
            transform: translateY(-9%) translateX(21%);
            z-index: 2;
            .security {
                width: 200px;
                height: 17px;
                font-size: 16px;
                font-family: Arial-Bold, Arial;
                font-weight: bold;
                color: #FFF;    
                text-align: center;
                letter-spacing: 4px;
            }
        }
        .circle-j {
            font-family: Copperplate-Bold-x;
            animation: circle-J 5s;
            animation-fill-mode: forwards; 
        }
        
        .circle-c {
            font-family: Copperplate-Bold-x;
            animation: circle-C 1.5s;
            animation-fill-mode: forwards; 
        }
        @keyframes circle-J {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        @keyframes circle-C {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
        .ellipse {
            width: 526px;
            height: 526px;
            border: 3px dashed #9333EA;
            border-radius: 263px;
            position: absolute;
            transform:  translateX(29%) translateY(-20%);
        }
        .ellipse-j {
            animation: ellipse-J 3s;
            animation-fill-mode: forwards; 
        }
        .ellipse-c {
            animation: ellipse-C 1.5s;
            animation-fill-mode: forwards;
        }
        @keyframes ellipse-J {
            0% {
                opacity: 0;
                transform: translateX(300px) translateY(-20%);
            }
            100% {
                opacity: 1;
                transform: translateX(29%) translateY(-20%);
            }
        }
        @keyframes ellipse-C {
            0% {
                opacity: 1;
                transform: translateX(29%) translateY(-20%);
            }
            100% {
                opacity: 0;
                transform: translateX(300px) translateY(-20%);
            }
        }

        .fourCircleBox {
            position: absolute;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            z-index: 2;
            text-align: end;
            .circleX {
                padding: 2px;
                background: #000F19;
                border: 3px solid #9333EA;
                border-radius: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                >div {
                    width: 56px;
                    height: 56px;
                    background: #000F19;
                    border-radius: 28px;
                }
            }
            .txt1 {
                margin-top: -14px;
                font-size: 29px;
                font-family: Arial-Bold,Arial;
                font-weight: bold;
                color: #FFFFFF;
                padding-right: 25px;
            }
            .txt2 {    
                margin-top: 8px;
                width: 455px;
                font-size: 18px;
                font-family: Courier New-Regular, Courier New;
                font-weight: 400;
                color: #FFFFFF;
                margin-right: 25px;
            }
        }
        .fourCircleBox:hover {
            .circleX {
                >div {
                    background: #9333EA;
                }
            }
            .txt1 {
                color: #9333EA;
            }
        }
        .fourCircleBoxHover {
            .circleX {
                >div {
                    background: #9333EA;
                }
            }
            .txt1 {
                color: #9333EA;
            }
        }
        .transformBox1 {
            transform: translateX(-507px) translateY(152px);
            .circleX {
                >div {
                    // background: #1673FF;
                }
            }
            .txt1 {
                // color: #1673FF;
            }
        }
        .transformBox2 {
            transform: translateX(-381px) translateY(-1px);
            .circleX {
                >div {
                    // background: #1673FF;
                }
            }
            .txt1 {
                // color: #1673FF;
            }
        }
        
        .transformBox3 {
            transform: translateX(-371px) translateY(465px);
            .circleX {
                >div {
                    // background: #1673FF;
                }
            }
            .txt1 {
                // color: #1673FF;
            }
        }

        .transformBox4 {
            transform: translateX(-498px) translateY(313px);
            .circleX {
                >div {
                    // background: #1673FF;
                }
            }
            .txt1 {
                // color: #1673FF;
            }
        }
    }
`

const StyledContainer6 = styled(StyleBase)<{isMobile?: boolean }>`
    height: ${({isMobile}) => isMobile ?  '1083px' : '700px' };
    min-height: 700px;
    // min-width: 1475px;
    box-sizing: border-box;
    display: flex;
    background: #000F19;
    flex-direction: ${({isMobile}) => isMobile ?  'row' : 'row' };
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    background: url('/images/home/home_bg5.png');
    background-size: 100% 100%;
    
    .lmgbox {            
        width: 54vw;
        max-width: 1020px;
        minx-width: 710px;
        margin-left: 258px;
        margin-bottom: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transform: translateY(17px);
        img {
            width: 68px;
        }
    }
    .footer-j {
        animation: footer-J 3s;
        animation-fill-mode: forwards; 
    }
    
    @keyframes footer-J {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .footer-c {
        animation: footer-C 0.5s;
        animation-fill-mode: forwards; 
    }    
    @keyframes footer-C {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    .footer {
        position: absolute;
        bottom: 0px;
        width: 100vw;
        box-shadow:0 0 3px 3px #1D0A2F;
        padding:  ${({isMobile}) => isMobile ?  '20px 0' : '20px 0' };
        background: linear-gradient(to right, #1C092D, #1D0A2F); 
        min-width: ${({isMobile}) => isMobile ?  '100vw' : '100vw' };   
        display: flex;
        justify-content: center;
        align-items: center;
        .footer-box {
            display: flex;
            justify-content: space-between;
            align-items: start;
            flex-direction: column;
            // padding-bottom: 40px;
            transform: ${({isMobile}) => isMobile ?  'scale(1)' : 'scale(1)' };
        }
        .your_ai {
            margin-top: 10px;
            font-size: 15px;
        }
        .footerRight {
            margin-top: 10px;    
            >span {
                text-align: end;
                font-size: 12px;
                font-family: Courier New-Regular, Courier New;
                font-weight: 400;
                color: #FFFFFF;
                transform: translateY(17px) translateY(10px);
                >span {
                    font-size: 15px;
                    font-weight: 800;
                    color: #fff;  
                    margin-right: 1vw;
                    a {
                        color: #fff;  
                        text-decoration:none
                    }
                }
            }
        }
        .footerLeft {
            >div {
                display: flex;
                justify-content: center;
                align-items: end;
                font-size: 18px;
                font-family: Courier New-Regular, Courier New;
                font-weight: 400;
                color: #FFFFFF;
                >span {
                    width: 127px;
                    height: 43px;
                    font-size: 30px;
                    font-family: Copperplate-Bold, Copperplate;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 36px;
                    margin-left: 16px;
                }
            }
        }
    }
    .Container7Box {
        position: absolute;    
        width: 737px;
        // height: 737px;
        transform: ${({isMobile}) => isMobile ?  'translateY(67px) translateX(41%) scale(1)' : 'translateY(-47px) translateX(28%) scale(0.7)' };
        .img1 {    
            position: absolute;
            top: -256px;
            z-index: 2;
        }
        .txtBox {
            position: absolute;
            height: 130px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: start;
            border-bottom: 1px solid #9333EA;
            .txt1 {
                width: 450px;
                height: 57px;
                font-size: 40px;
                font-family: Arial-Bold,Arial;
                font-weight: bold;
                line-height: 0px;
            }
            .txt2 {
                width: 391px;
                font-size: 20px;
                line-height: 22px;
                font-family: Courier New-Regular, Courier New;
                font-weight: 400;
            }
            .line {
                position: absolute;
                height: 1px;
                background: #9333EA;
            }
        }

        .txtBox1 {
            width: 450px;
            transform: translateX(-556px) translateY(-390px);
            .line1 {
                width: 122px;
                transform: translateX(442px) translateY(96px) rotateZ(30deg);
            }
        }
        .box-txt-j {
            animation: box-txt-J 1.5s;
            animation-fill-mode: forwards;
        }
        .box-txt-c {
            animation: box-txt-C 1.5s;
            animation-fill-mode: forwards;
        }
        
        @keyframes box-txt-J {
            0% {
                opacity: 0;
                transform: translateY(-200px);
            }
            100% {
                opacity: 1;
                transform: translateY(0px);
            }
        } 
        @keyframes box-txt-C {
            0% {
                opacity: 1;
                transform: translateY(0px);
            }
            100% {
                opacity: 0;
                transform: translateY(-200px);
            }
        } 
        
        .txtBox2 {
            width: 248px;
            transform: translateX(-408px) translateY(-85px);
            .txt1 {
                width: 248px;
            }
            .txt2 {
                width: 248px;
            }
            .line2 {
                width: 204px;
                transform: translateX(235px) translateY(18px) rotateZ(-28deg);
            }
        }

        .txtBox3 {
            width: 350px;
            transform: translateX(306px) translateY(-390px);
            .txt1 {
                width: 296px;
            }
            .txt2 {
                width: 350px;
            }
            .line3 {
                width: 118px;
                transform: translateX(-109px) translateY(95px) rotateZ(-30deg);
            }
        }        
        .txtBox4 {
            width: 329px;
            transform: translateX(306px) translateY(-0px);
            .txt1 {
                width: 487px;
            }
            .txt2 {
                width: 296px;
            }
            .line4 {
                width: 226px;
                transform: translateX(-197px) translateY(-10px) rotateZ(42deg);
            }
        }
    }
    
    .circle6-j {
        animation: circle6-J6 1.5s;
        animation-fill-mode: forwards;
    }
    .circle6-c {
        animation: circle6-C 0.5s;
        animation-fill-mode: forwards;
    }
    
    @keyframes circle6-J6 {
        0% {
            opacity: 0;
        }
        20% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    } 
    @keyframes circle6-C {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    } 
`



// eslint-disable-next-line import/prefer-default-export
export { StyledContainerLeft, StyledContainer1, StyledContainer2, StyledContainer3, StyledContainer4, StyledContainer5, StyledContainer6 }