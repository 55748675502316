/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'; 
import Login from 'components/Login'
import { useWeb3React } from '@web3-react/core'
import { useHistory } from 'react-router-dom'
import { interceptAdd } from 'utils/utils'

const StyleBase = styled.div<{isMobile?: boolean }>`
  margin-top: 37px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ddd;
  .h3 {
    font-size: ${({isMobile}) => isMobile ?  '20px' : '16px' };
    font-weight: 600;
  }
  h2 {
    font-size: ${({isMobile}) => isMobile ?  '50px' : '30px' };
  }
  >p {
    width: ${({isMobile}) => isMobile ?  '90vw' : '90vw' };
    max-width: ${({isMobile}) => isMobile ?  '1300px' : '1300px' };
    font-size: ${({isMobile}) => isMobile ?  '16px' : '12px' };
    a {
      text-decoration:none;
      color: #fff;
    }
  }
`

const Footer = styled.div<{isMobile?: boolean }>`
.footer {
  width: 100vw;
  transform: translateY(79px);
  box-shadow:0 0 3px 3px #1D0A2F;
  background: linear-gradient(to right, #1C092D, #1D0A2F); 
  min-width: ${({isMobile}) => isMobile ?  '100vw' : '1590px' };   
  display: flex;
  justify-content: center;
  align-items: center;
  word-spacing: 1px;    
  padding-top: 44px;
  .lmgbox {
    width: 961px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;
    margin-left: 310px;
  }
  .footer-box {
      display: flex;
      justify-content: space-between;
      align-items: start;
      flex-direction: column;
      padding-bottom: 40px;
  }
  .your_ai {
      margin-top: 10px;
      font-size: 15px;
  }
  .footerRight {
      margin-top: 10px;    
      >span {
          text-align: end;
          font-size: 12px;
          font-family: Courier New-Regular, Courier New;
          font-weight: 400;
          color: #FFFFFF;
          >span {
              font-size: 15px;
              font-weight: 800;
              color: #fff;  
              margin-left: 60px;
              a {
                  color: #fff !important;  
                  text-decoration:none
              }
              a:active {
                color: #fff;  
              }
          }
      }
  }
  .footerLeft {
      >div {
          display: flex;
          justify-content: center;
          align-items: end;
          font-size: 18px;
          font-family: Courier New-Regular, Courier New;
          font-weight: 400;
          color: #FFFFFF;
          >span {
              width: 127px;
              height: 43px;
              font-size: 30px;
              font-family: Copperplate-Bold, Copperplate;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 36px;
              margin-left: 16px;
          }
      }
  }
}
`

const MobileFooter = styled.div<{isMobile?: boolean }>`
.footer {
  width: ${({isMobile}) => isMobile ?  '' : '354px' };
  display: flex;
  flex-direction: ${({isMobile}) => isMobile ?  '' : 'column' };
  align-items: ${({isMobile}) => isMobile ?  'end' : '' };
  margin-top: 83px;    
  word-spacing: -3px;
  .footerTop {
      margin-top: 90px;
      color: #9333EA;
      font-size: 14px;
      display: flex;
      justify-content: space-between;    
      margin-bottom: 76px;
      a {
          color: #9333EA;  
          text-decoration:none
      }
  }
  .footerLeft {
      margin-top: 20px;
      margin-right: 20px;
      >div {
          font-size: 12px;
          font-family: Courier New-Regular, Courier New;
          font-weight: 400;
          color: #FFFFFF;
          >span {
              width: 127px;    
              font-size: 16px;
              font-family: Copperplate-Bold-x;
              font-weight: bold;
              color: #FFFFFF;
              margin-left: 16px;
          }
      }
  }
  .footerRight {
    margin-top: ${({isMobile}) => isMobile ?  '56px' : '' };
      >div {
          font-size: 12px;
          font-family: Courier New-Regular, Courier New;
          font-weight: 400;
          color: #FFFFFF;
          >span {
              margin-top: 30px;
              font-size: 12px;
              margin-right: 6px;
              font-weight: 800;
              a {
                  color: #FFFFFF;
                  text-decoration:none;
              }
          }
      }
  }
}
`


const StyledHeader = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'row' };
    justify-content: end;
    color: #FFFFFF;
    padding-top: 20px;
    font-size: 15px;
    font-family: Copperplate-Bold, Copperplate;
    font-weight: bold;
    color: #000F19;
    img{
      position: absolute;
      top: 20px;
      left: 0px;
      width: 39.92px;
      height: 50.39px;
      margin-left: 19px;
      
    }
    .AEGIS {
      position: absolute;    
      top: 34px;
      left: 68px;
      font-size: 16px;
      font-family: 'Copperplate-Bold-x';
      color: #FFFFFF;
    }
    .me-txt {
      position: absolute;    
      top: 30px;
      right: 0px;
      display: flex;
      margin-right: 16px;
    }
    .styledHeaderTextM {
      width: 83px;
      height: 33px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 2px solid #505763;
      font-size: 14px;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 30px;
    }
    .styledHeaderTextC {
      width: 127px;
      height: 33px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 2px solid #505763;
      font-size: 14px;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 30px;
      margin-left: 13px;
    }
`

export default function Index() { 
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1142px)').matches,
  );  
  const [matches700, setMatches700] = useState(
    window.matchMedia('(min-width: 700px)').matches,
  );  
  const { account } = useWeb3React()
  useEffect(() => {
    window
      .matchMedia('(min-width: 1142px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });

    
      window
      .matchMedia('(min-width: 700px)')
      .addEventListener('change', (e) => {
        setMatches700(e.matches)
      });
    
    if(!matches) {
      document.body.style.overflowX = ''
      document.body.style.overflowY = ''
    } else {
      document.body.style.overflowX = 'hidden'
      document.body.style.overflowY = ''
    }
    return () => { 
      // document.body.style.overflowY = 'hidden'
      }
    
  }, [matches]);

  return (
    <StyleBase isMobile={matches}> 
      <StyledHeader>
        <img onClick={() => { 
              window.location.href = '/'
             }} className='corsor-pointer' src="/images/home/logo.svg" alt='' />
        <span onClick={() => { 
              window.location.href = '/'
             }} className='AEGIS corsor-pointer'>AEGIS</span>
        {/* <div className='me-txt'>
          <div className='styledHeaderTextM'>Medium</div>
            <Login
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}>
          <div className='styledHeaderTextC'>{interceptAdd(account)}</div></Login>
        </div> */}
      </StyledHeader>
      <h2>Privacy Policy</h2>
      <p>Last updated:Dec 01, 2022.</p>
      <p>DUOYU INC or any related entity (the "Company," "we," or "us") would like to inform you on how we collect, use and disclose information. This Privacy Policy describes our practices in connection with information that we collect through websites operated by us from which you are accessing this Privacy Policy (the "Websites"); which may be accessed through HTML-formatted email message(s) that we send to you that link to this Privacy Policy. Collectively, we refer to the above as the "Service."</p>
      <p>We take the protection of your personal data very seriously. By using the Service, you agree to the collection and use of information in accordance with this policy. We collect several different types of information for various purposes to provide and improve our Service to you.</p>
      <p className='h3'>Contact Email</p>
      <p>When you send us an email we will store your provided personal information in order to process your inquiry and to provide you with our services to the extent applicable. We will keep copies of such communication as long as it is necessary for dealing with your inquiry.</p>
      <p className='h3'>Tracking & Cookies Data</p>
      <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. Cookies are files with a small amount of data that our website stores on your device to collect and track information and to improve and analyze our Service.</p>
      <p className='h3'>Changes to this Privacy Policy</p>
      <p>We may occasionally revise our privacy policy by posting the changes here.</p>
      <p>By accessing any part of the Websites or Service, you agree that you are providing your express consent to the terms and conditions of this Privacy Policy.</p>
      <p className='h3'>Contact Details</p>
      <p>If you have further questions with regards to this privacy policy do not hesitate to contact us at <a href="mailto:hi@aegis.top">hi@aegis.top</a>  with the subject line "Privacy Policy”.</p>
      {
        matches ?
        <Footer>
        <div className='footer'>
          <div className='footer-box'>
            <div className='footerLeft'>
                <div style={{marginBottom: '13px'}}>
                  <img src="/images/home/logo.svg" alt=''  /> <span>AEGIS</span>  
                  {/* <div className='lmgbox'>
                    <img className='img1' src="/images/home/baidu.png" alt="" />
                    <img className='img2' src="/images/home/huobi.png" alt="" />
                    <img className='img3' src="/images/home/motorolo.png" alt="" />
                    <img className='img4' src="/images/home/tenxun.png" alt="" />
                    <img className='img5' src="/images/home/360.png" alt="" />
                    <img className='img6' src="/images/home/openpasp.png" alt="" />
                    <img className='img7' src="/images/home/Teaclave.png" alt="" />
                  </div> */}
                </div>
              </div>
              
              <div className='your_ai'>Your AI Security Guard of Web3</div>
              <div className='footerRight'>
                <span>Copyright @2022 Aegis Labs, Inc. All rights reserved.</span><span style={{marginLeft: '77px'}}>  <span className='corsor-pointer' onClick={() => { history.push('/TermsOfService') }}>Terms of Service</span> <span className='corsor-pointer' onClick={() => { history.push('/PrivacyPolicy') }}>Privacy Policy</span> <span><a href="mailto:hi@aegis.top">hi@aegis.top</a></span></span> 
              </div> 
            </div>
        </div>
      </Footer> : 
      <MobileFooter isMobile={matches700}>
        <div className='footer'>
          <div className='footerLeft'>
            <div style={{width: '181px', marginBottom: '13px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src="/images/home/logo.svg" alt=''  /> <span>AEGIS</span>
            </div>
            <div style={{width: '217px', marginBottom: matches700 ? '' : '30px'}}>Your AI Security Guard of Web3</div>
          </div>
          <div className='footerRight'>
            <div>
            <span onClick={() => { history.push('/TermsOfService') }}>Terms of Service</span> <span onClick={() => { history.push('/PrivacyPolicy') }}>Privacy Policy</span> <span><a href="mailto:hi@aegis.top">hi@aegis.top</a></span>
              <div style={{marginTop: '13px'}}>Copyright @2022 Aegis Labs, Inc. All rights reserved.</div>
            </div>
          </div>
        </div>
      </MobileFooter>
      }
    </StyleBase>
  )
}