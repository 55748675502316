/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { flagZt } from 'utils/utils'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'; 

const StyleBase = styled.div<{isMobile?: boolean,isMobile700?: boolean,flagZt?: boolean,StyledContainer1Ref: any,StyledContainer2Ref: any,StyledContainer3Ref: any,StyledContainer4Ref: any,StyledContainer5Ref: any,StyledContainer6Ref: any }>`
    >div {
      display: ${Cookies.getJSON('cookie-policy__accept') ? 'none' : 'flex'};
      flex-direction: ${({isMobile}) => isMobile ?  'row' : 'column' };
      justify-content: space-evenly;
      align-items: center;
      position: ${({isMobile}) => isMobile ?  'fixed' : 'fixed' };
      bottom: ${({isMobile700}) => isMobile700 ?  '0px' : '' };
      top: ${({isMobile700}) => isMobile700 ?  '' : '0px' };
      // font-family: Arial-Bold,Arial;
      width: ${({isMobile700}) => isMobile700 ?  '100vw' : '100vw' };
      ${({isMobile700}) => isMobile700 ?  'min-width' : 'min-width' }: ${({isMobile700}) => isMobile700 ?  '100vw' : '100vw' };
      height: ${({isMobile}) => isMobile ?  '141px' : '' };
      text-align: start;
      background: #321348;
      z-index: 999;
      line-height: 20px;    
      transition: top 1s;
    }
    .x {    
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 18px;
      color: #fff;
    }
    .e {
      width: ${({isMobile}) => isMobile ?  '' : '90%' };
      font-size: ${({isMobile}) => isMobile ?  '13px' : '12px' };
      // font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #F2F2F2;
      margin-top: ${({isMobile}) => isMobile ?  '0' : '20px' };
      margin-bottom: ${({isMobile}) => isMobile ?  '0' : '0px' };
      margin-left: ${({isMobile}) => isMobile ?  '50px' : '0' };
      color: #ddd;
      span {
        color: #fff;
        font-size: ${({isMobile}) => isMobile ?  '13px' : '12px' };
        font-weight: 800;
        text-decoration:none;
      }
    }
    .btn {
      width: ${({isMobile}) => isMobile ?  '' : '90%' };
      display: ${({isMobile}) => isMobile ?  'block' : 'flex' };
      justify-content: space-evenly;
      margin-bottom: ${({isMobile}) => isMobile ?  '' : '20px' };
      margin-top: ${({isMobile}) => isMobile ?  '' : '10px' };    
      margin-left: ${({isMobile}) => isMobile ?  '15px' : '' };
      button {
        border-radius: 6px 6px 6px 6px;
        padding: ${({isMobile}) => isMobile ?  '10px 16px' : '5px 10px' };
        font-size: 16px;
        // font-family: Arial-Regular, Arial;
        font-weight: 400;
        color: #fff;
        background: #a75af2;
        margin-right: ${({isMobile}) => isMobile ?  '50px' : '0' };
        border: none;
      }
    }
`

export default function Index(props: any) {
  const history = useHistory()
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1142px)').matches,
  );
  const [matches700, setMatches700] = useState(
    window.matchMedia('(min-width: 700px)').matches,
  );
  const page = props?.page

  useEffect(() => {
    window
      .matchMedia('(min-width: 1142px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
      
    window
    .matchMedia('(min-width: 700px)')
    .addEventListener('change', (e) => {
      setMatches700(e.matches)
    });
  }, []);
  return (
    <StyleBase 
      isMobile={flagZt && matches} 
      isMobile700={matches700}
      flagZt={flagZt}
      StyledContainer1Ref={props?.StyledContainer1Ref}
      StyledContainer2Ref={props?.StyledContainer2Ref}
      StyledContainer3Ref={props?.StyledContainer3Ref}
      StyledContainer4Ref={props?.StyledContainer4Ref}
      StyledContainer5Ref={props?.StyledContainer5Ref}
      StyledContainer6Ref={props?.StyledContainer6Ref}
    >
      <div className={page === 1 ? 'top_1' : page === 2 ? 'top_2' : page === 3 ? 'top_3' : page === 4 ? 'top_4' : page === 5 ? 'top_5': 'top_6'}>
        <div className='x corsor-pointer' onClick={() => { 
            Cookies.set('cookie-policy__accept', 'true')
            props?.setCookies(true)
          }}>X</div>
        <div className='e'>
          <div>We use cookies to offer you a better browsing experience, analyze site traffic, personalize content, and serve targeted advertisements. Read about how we use cookies and how you can control them on our <span className='corsor-pointer' onClick={() => { history.push('/PrivacyPolicy') }}>Privacy Policy</span>.</div>
          <div>If you continue to use this site, you consent to our use of cookies.</div>
        </div>
        <div className='btn'>
          <button className='corsor-pointer' onClick={() => { 
            Cookies.set('cookie-policy__accept', 'true')
            props?.setCookies(true)
          }}>ACCEPT</button>
        </div>
      </div>
    </StyleBase>
  )
}
