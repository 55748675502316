/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Login from 'components/Login'
import { useWeb3React } from '@web3-react/core'
import { interceptAdd } from 'utils/utils'
import { useHistory } from 'react-router-dom'

const StyleBase = styled.div<{isMobile?: boolean }>`
  margin-top: 37px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ddd;
  .h3 {
    font-size: ${({isMobile}) => isMobile ?  '20px' : '16px' };
    font-weight: 600;
  }
  h2 {
    font-size: ${({isMobile}) => isMobile ?  '50px' : '30px' };
  }
  >p {
    width: ${({isMobile}) => isMobile ?  '90vw' : '90vw' };
    max-width: ${({isMobile}) => isMobile ?  '1300px' : '1300px' };
    font-size: ${({isMobile}) => isMobile ?  '16px' : '12px' };
  }
`
const Footer = styled.div<{isMobile?: boolean }>`
.footer {
  width: 100vw;
  transform: translateY(79px);
  box-shadow:0 0 3px 3px #1D0A2F;
  background: linear-gradient(to right, #1C092D, #1D0A2F); 
  min-width: ${({isMobile}) => isMobile ?  '100vw' : '1590px' };   
  display: flex;
  justify-content: center;
  align-items: center;
  word-spacing: 1px;    
  padding-top: 44px;
  .lmgbox {
    width: 961px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;
    margin-left: 310px;
  }
  .footer-box {
      display: flex;
      justify-content: space-between;
      align-items: start;
      flex-direction: column;
      padding-bottom: 40px;
  }
  .your_ai {
      margin-top: 10px;
      font-size: 15px;
  }
  .footerRight {
      margin-top: 10px;    
      >span {
          text-align: end;
          font-size: 12px;
          font-family: Courier New-Regular, Courier New;
          font-weight: 400;
          color: #FFFFFF;
          >span {
              font-size: 15px;
              font-weight: 800;
              color: #fff;  
              margin-left: 60px;
              a {
                  color: #fff;  
                  text-decoration:none
              }
          }
      }
  }
  .footerLeft {
      >div {
          display: flex;
          justify-content: center;
          align-items: end;
          font-size: 18px;
          font-family: Courier New-Regular, Courier New;
          font-weight: 400;
          color: #FFFFFF;
          >span {
              width: 127px;
              height: 43px;
              font-size: 28px;
              font-family: Copperplate-Bold-x;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 36px;
              margin-left: 16px;
          }
      }
  }
}
`

const MobileFooter = styled.div<{isMobile?: boolean }>`
  .footer {
  width: ${({isMobile}) => isMobile ?  '' : '354px' };
  display: flex;
  flex-direction: ${({isMobile}) => isMobile ?  '' : 'column' };
  align-items: ${({isMobile}) => isMobile ?  'end' : '' };
  margin-top: 33px;
  margin-bottom: 20px;
  word-spacing: -3px;    
  .footerTop {
      margin-top: 90px;
      color: #9333EA;
      font-size: 14px;
      display: flex;
      justify-content: space-between;    
      margin-bottom: 76px;
      a {
          color: #9333EA;  
          text-decoration:none
      }
  }
  .footerLeft {
      margin-top: 20px;
      margin-right: 20px;
      >div {
          font-size: 12px;
          font-family: Courier New-Regular, Courier New;
          font-weight: 400;
          color: #FFFFFF;
          >span {
              width: 127px;
              height: 43px;
              font-size: 16px;
              font-family: Copperplate-Bold, Copperplate;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 36px;
              margin-left: 16px;
          }
      }
  }
  .footerRight {
    margin-top: ${({isMobile}) => isMobile ?  '56px' : '' };
      >div {
          font-size: 12px;
          font-family: Courier New-Regular, Courier New;
          font-weight: 400;
          color: #FFFFFF;
          >span {
              margin-top: 30px;
              font-size: 12px;
              margin-right: 13px;
              font-weight: 800;
              a {
                  color: #FFFFFF;
                  text-decoration:none;
              }
          }
      }
  }
}
`


const StyledHeader = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'row' };
    justify-content: end;
    color: #FFFFFF;
    padding-top: 20px;
    font-size: 15px;
    font-family: Copperplate-Bold, Copperplate;
    font-weight: bold;
    color: #000F19;
    img{
      position: absolute;
      top: 20px;
      left: 0px;
      width: 39.92px;
      height: 50.39px;
      margin-left: 19px;
      
    }
    .AEGIS {
      position: absolute;    
      top: 34px;
      left: 68px;
      font-size: 16px;
      font-family: 'Copperplate-Bold-x';
      color: #FFFFFF;
    }
    .me-txt {
      position: absolute;    
      top: 30px;
      right: 0px;
      display: flex;
      margin-right: 16px;
    }
    .styledHeaderTextM {
      width: 83px;
      height: 33px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 2px solid #505763;
      font-size: 14px;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 30px;
    }
    .styledHeaderTextC {
      width: 127px;
      height: 33px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 2px solid #505763;
      font-size: 14px;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 30px;
      margin-left: 13px;
    }
`

export default function Index() {  
  const history = useHistory()
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1142px)').matches,
  );  
  const [matches700, setMatches700] = useState(
    window.matchMedia('(min-width: 700px)').matches,
  );  
  const { account } = useWeb3React()

  useEffect(() => {
    window
      .matchMedia('(min-width: 1142px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      }); 
    window 
      .matchMedia('(min-width: 700px)')
      .addEventListener('change', (e) => {
        setMatches700(e.matches)
      });   
      if(!matches) {
        document.body.style.overflowX = ''
        document.body.style.overflowY = ''
      } else {
        document.body.style.overflowX = 'hidden'
        document.body.style.overflowY = ''
      }
    return () => { 
      // document.body.style.overflowY = 'hidden'
     }
  }, [matches]);
  return (
    <StyleBase isMobile={matches}> 
    <StyledHeader>
      <img onClick={() => { 
              window.location.href = '/'
             }} className='corsor-pointer' src="/images/home/logo.svg" alt='' />
      <span onClick={() => { 
              window.location.href = '/'
             }} className='AEGIS corsor-pointer'>AEGIS</span>
      {/* <div className='me-txt'>
          <Login
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}>
        <div className='styledHeaderTextC'>{interceptAdd(account)}</div></Login>
      </div> */}
    </StyledHeader>
      <h2>Terms of Use</h2>
      <p>Last updated:Dec 01, 2022.</p>
      <p>DUOYU INC or any related entity (the "Company," "we," or "us") would like to inform you about the terms of use of the websites operated by us from which you are accessing this Terms of Use (the "Websites"); which may be accessed through HTML-formatted email message(s) that we send to you that link to this Terms of Use. Collectively, we refer to the above as the "Service".</p>
      <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
      <p className='h3'>Conditions of Use</p>
      <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use any of our Websites if you do not agree to take all of the terms and conditions stated below.</p>
      <p className='h3'>Service Liability</p>
      <p>By accessing any part of the Websites or Service you agree and expressly understand that you do so at your sole risk. Our website, app, smart contracts are provided "as is" and "as available" without warranties of any kind, whether express or implied.</p>
      <p className='h3'>Reservation of Rights</p>
      <p>We reserve the right to request that you remove all links or any particular link to our Websites. You approve to immediately remove all links to our Websites upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Websites, you agree to be bound to and follow these linking terms and conditions.</p>
      <p>Without prior approval and written permission, you may not create frames around our website that alter in any way the visual presentation or appearance.</p>
      <p className='h3'>Link Liability</p>
      <p>Our offer contains links to external websites of third parties, the content of which we have no influence. For this reason, we cannot accept any liability for this external content. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. No illegal content was discernible at the time the link was created.</p>
      <p>A permanent control of the content of the linked pages is not reasonable without concrete evidence of an infringement. As soon as we become aware of legal violations, we will remove such links immediately.</p>
      {
        matches ?
        <Footer>
        <div className='footer'>
          <div className='footer-box'>
            <div className='footerLeft'>
                <div style={{marginBottom: '13px'}}>
                  <img src="/images/home/logo.svg" alt=''  /> <span>AEGIS</span>  
                  {/* <div className='lmgbox'>
                    <img className='img1' src="/images/home/baidu.png" alt="" />
                    <img className='img2' src="/images/home/huobi.png" alt="" />
                    <img className='img3' src="/images/home/motorolo.png" alt="" />
                    <img className='img4' src="/images/home/tenxun.png" alt="" />
                    <img className='img5' src="/images/home/360.png" alt="" />
                    <img className='img6' src="/images/home/openpasp.png" alt="" />
                    <img className='img7' src="/images/home/Teaclave.png" alt="" />
                  </div> */}
                </div>
              </div>
              
              <div className='your_ai'>Your AI Security Guard of Web3</div>
              <div className='footerRight'>
                <span>Copyright @2022 Aegis Labs, Inc. All rights reserved.</span>
                <span style={{marginLeft: '77px'}}> <span className='corsor-pointer' onClick={() => { history.push('/TermsOfService') }}>Terms of Service</span> <span className='corsor-pointer' onClick={() => { history.push('/PrivacyPolicy') }}>Privacy Policy</span> <span><a href="mailto:hi@aegis.top">hi@aegis.top</a></span> </span>
              </div> 
            </div>
        </div>
      </Footer> : 
      <MobileFooter isMobile={matches700}>
        <div className='footer'>
          <div className='footerLeft'>
            <div style={{width: '181px', marginBottom: '13px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src="/images/home/logo.svg" alt=''  /> <span>AEGIS</span>
            </div>
            <div style={{width: '217px', marginBottom: matches700 ? '' : '30px'}}>Your AI Security Guard of Web3</div>
          </div>
          <div className='footerRight corsor-pointer'>
            <div>
            <span onClick={() => { history.push('/TermsOfService') }}>Terms of Service</span> <span onClick={() => { history.push('/PrivacyPolicy') }}>Privacy Policy</span><span><a href="mailto:hi@aegis.top">hi@aegis.top</a></span> 
              <div style={{marginTop: '13px'}}>Copyright @2022 Aegis Labs, Inc. All rights reserved.</div>
            </div>
          </div>
        </div>
      </MobileFooter>
      }
    </StyleBase>
  )
}