/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie';
import styled from 'styled-components'; 
import { useWeb3React } from '@web3-react/core'
import { useHover,  useInViewport } from 'ahooks' 
import Login from 'components/Login'
import Agreement from 'components/Agreement'
import { interceptAdd } from 'utils/utils'
import { useHistory } from 'react-router-dom'
import Web3Form from 'components/Web3Form'
import { StyledContainerLeft, StyledContainer1, StyledContainer2, StyledContainer3, StyledContainer4, StyledContainer5, StyledContainer6 } from './indexStyles'

const StyleBase = styled.div``

const Wrapper = styled(StyleBase)<{isMobile?: boolean}>`
  min-height: 100vh;
  >main {
    position: absolute;
    animation: top 1s;
    width: 100vw;
  }
`
const StyledHeader = styled(StyleBase)<{isMobile?: boolean}>`
    position: absolute;
    min-width: 100vw;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'row' };
    justify-content: space-between;
    color: #FFFFFF;
    padding-top: 40px;
    font-size: 15px;
    font-family: Copperplate-Bold, Copperplate;
    font-weight: bold;
    img {
      position: fixed;
      width: 39.92px;
      height: 50.39px;
      margin-left: 50px;
      transition: top 1000ms;
      cursor: pointer;
    }
    .AEGIS {
      position: absolute;
      top: 53px;
      left: 108px;
      font-size: 18px;
      font-family: 'Copperplate-Bold-x';
    }
    .img_h_1{
      top: 40px;
    }
    >div {
      display: flex;
      margin-right: 60px;
      position: absolute;
      right: 0;
    }
    .link_H {
      top: 40px;
      margin-right: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 37px;
        height: 37px;
        margin-left: 20px;
      }
      img:hover {
        transform: scale(1.2);
      }
    }
    .styledHeaderTextM {
      width: 123px;
      height: 58px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 2px solid #505763;
      font-size: 16px;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 58px;
    }
    .styledHeaderTextC {
      width: 198px;
      height: 58px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 2px solid #505763;
      font-size: 20px;
      font-family: Arial-Regular, Arial;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 56px;
      margin-left: 53px;
    }
    .styledHeaderTextC-hover:hover {
      color: #FEFDFE;
      background: #9333EA;
      border: 0px solid #505763;
      line-height: 60px;
    }
`

const StyledA1Right = styled(StyleBase)<{isMobile?: boolean }>`
    margin-left: 12px;
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    margin-right: ${({isMobile}) => isMobile ?  '0px' : '61px' };
    justify-content: center;
    align-items: center;
    width: 30vw;
    min-width: 380px;
    height: 30vw;
    min-height: 380px;
    background-size: auto 100%;
    color: #000F19;
    .a {
      height: 259px;
      font-size: 183px;
      font-weight: bold;
      color: #000F19;
      line-height: 259px;
      z-index: 5;
    }
    .a-j {
      animation: A-J 3s;
      animation-fill-mode: forwards;
    }
    .a-c {
      animation: A-C 1.5s;
      animation-fill-mode: forwards;
    }
    .protect {
      font-size: 31px;
      font-family: 'Copperplate-Bold-x';
      font-weight: 800;
      color: #484848;
      z-index: 5;
      margin-top: 15px;
    }
    
    @keyframes A-J {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }  
    @keyframes A-C {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    } 
`

const Dream = styled(StyleBase)<{isMobile?: boolean }>`
        position: absolute;
        :root {
          --deg: 1;
          --x: -50%;
          --y: -50%;
        }

        div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(var(--x, -50%), var(--y, -50%)) rotate(0deg);
          font-size: 20vmin;
          width: 30vw;
          min-width: 380px;
          max-width: 480px;
          height: 30vw;
          min-height: 380px;
          max-height: 480px;
          border-radius: 90% 95% 85% 105%;
          background: #0f0;
          mix-blend-mode: screen;
          filter: hue-rotate(0deg);
          animation: wobble calc(150ms * var(--t)) linear infinite;
          transform-origin: -var(--y) -var(--x);
          box-shadow: 0 0 .5em .2em #000 inset, 0 0 .15em 0 #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          &::after {
            font-size: 1em;
            // content: '☻';
            white-space: nowrap;
          }
          &:nth-child(1) {
            --x: -53%;
            --y: -53%;
            --t: 37;
          }
          &:nth-child(2) {
            --x: -47%;
            --y: -52%;
            --t: 58;
          }
          &:nth-child(3) {
            --x: -45%;
            --y: -50%;
            --t: 46;
          }
          &:nth-child(4) {
            --x: -53%;
            --y: -45%;
            --t: 72;
          }
          &:nth-child(5) {
            --x: -55%;
            --y: -45%;
            --t: 62;
          }
        }

        @keyframes wobble {
          to {
            filter: hue-rotate(360deg);
            transform: translate(var(--x), var(--y)) rotate(360deg);
          }
        }
`


const StyledA1Left = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    justify-content: center;
    align-items: start;
    color: #FFFFFF;
    margin-left: ${({isMobile}) => isMobile ?  '0px' : '95px' };
    .Upgrade {
      width: 566px;
      height: 26px;
      font-family: Arial-Regular,Arial;
      font-weight: bold;
      color: #000F19;
      font-size: 20px;
      font-weight: 400;
      .avalanche {
        font-family: Arial-Bold, Arial;
        font-weight: bold;
        color: #9333EA;
        border-bottom: 2px solid #9333EA;
        padding-bottom: 2px;
      }
      .ff {
        width: 20px;
        height: 20px;
        background: #1673FF;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }
    .c {
      animation: C 3s;
      animation-fill-mode: forwards;
    }
    .j {
      animation: J 3s;
      animation-fill-mode: forwards;
    }
    .your {
      width: 626px;
      height: 205px;
      font-size: 76px;
      font-family: Arial-Bold, Arial;
      font-weight: bold;
      color: #FFFFFF;
      margin-top: 22px;
    }
    .aegis {
      width: 568px;
      height: 60px;
      font-size: 20px;
      font-family: Courier New-Regular, Courier New;
      font-weight: 400;
      color: #E1E1E1;
    }
    .join {
      width: 263px;
      height: 58px;
      background: #9333EA;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 14px;
      font-family: Copperplate-Bold-x;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      line-height: 58px;
      margin-top: 39px;
    }
    .join:hover {
      color: #FEFDFE;
      background: #B360FF;
    }
    @keyframes J {
        0% {
          opacity: 0;
          transform: translateX(-200px);
        }
        100% {
          opacity: 1;
          transform: translateX(-0px);
        }
    }
    @keyframes C {
        0% {
          opacity: 1;
          transform: translateX(0px);
        }
        100% {
          opacity: 0;
          transform: translateX(-200px);
        }
    }
`
// right.png

export default function Index() {
  const history = useHistory()
  const StyledContainer1Ref = useRef(null);
  const StyledContainer2Ref = useRef(null);
  const cardRightRef = useRef(null);
  const StyledContainer3Ref = useRef(null);
  const StyledContainer4Ref = useRef(null);
  const StyledContainer5Ref = useRef(null);
  const StyledContainer6Ref = useRef(null);
  const Container1RefVisibility = useInViewport(StyledContainer1Ref);
  const cardRightRefVisibility = useInViewport(cardRightRef);
  const Container3RefVisibility = useInViewport(StyledContainer3Ref);
  const Container4RefVisibility = useInViewport(StyledContainer4Ref);
  const Container5RefVisibility = useInViewport(StyledContainer5Ref);
  const Container6RefVisibility = useInViewport(StyledContainer6Ref);
  const WrapperRef = useRef(null);
  const StyledContainer4Num = useRef(null);
  const StyledContainer1Num = useRef(null);
  const [page, setPage] = useState(1);
  const [styledContainer4NumFour, setStyledContainer4NumFour] = useState(0);
  const [web3FormIsModalOpen, setWeb3FormIsModalOpen] = useState(false);
  const [page1Num, setPage1Num] = useState(0);
  const StyledContainer4Num1 = useRef(null);
  const StyledContainer4Num2 = useRef(null);
  const StyledContainer4Num3 = useRef(null);
  const StyledContainer4Num4 = useRef(null);
  const isHoveringNum1 = useHover(StyledContainer4Num1);
  const isHoveringNum2 = useHover(StyledContainer4Num2);
  const isHoveringNum3 = useHover(StyledContainer4Num3);
  const isHoveringNum4 = useHover(StyledContainer4Num4);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cookies,setCookies] = useState(Cookies.getJSON('cookie-policy__accept'))
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1440px)').matches,
  );
  const { account } = useWeb3React()

  useEffect(() => {
    document.documentElement.scrollTop = 1
    setPage1Num(0)
    let num = 0;
    StyledContainer1Num.current = setInterval(() => { 
      num ++
      setPage1Num(num%3)
    }, 1500)
    window
      .matchMedia('(min-width: 1440px)')
      .addEventListener('change', (e) => setMatches(e.matches));
    return () => { 
      clearInterval(StyledContainer4Num.current)
      clearInterval(StyledContainer1Num.current)
    }
  }, []);

  useEffect(() => { 
    clearInterval(StyledContainer4Num.current)
    if(isHoveringNum1) {
      setStyledContainer4NumFour(0)
    } else if(isHoveringNum2) {
      setStyledContainer4NumFour(1)
    } else if(isHoveringNum3) {
      setStyledContainer4NumFour(2)
    } else if(isHoveringNum4) {
      setStyledContainer4NumFour(3)
    } else {
      let num = styledContainer4NumFour;
      StyledContainer4Num.current = setInterval(() => { 
        num ++
        setStyledContainer4NumFour(num%4)
      }, 3000)
    }
  }, [isHoveringNum1, isHoveringNum2, isHoveringNum3, isHoveringNum4])

  return (
    <Wrapper 
      ref={WrapperRef}
    >
      <main className='roll'>
        <div id="home" ref={StyledContainer1Ref}>
          <StyledContainerLeft
            StyledContainer1Ref={StyledContainer1Ref}
            StyledContainer2Ref={StyledContainer2Ref}
            StyledContainer3Ref={StyledContainer3Ref}
            StyledContainer4Ref={StyledContainer4Ref}
            StyledContainer5Ref={StyledContainer5Ref}
            StyledContainer6Ref={StyledContainer6Ref}>
            <div className='active_left1'>
              <a href="https://twitter.com/aegishq" target="_blank" rel="noreferrer"><p className='left_img1 corsor-pointer' /></a>
              <a href="https://aegishq.medium.com/" target="_blank" rel="noreferrer"><p className='left_img2 corsor-pointer' /></a>
              {/* <p className='left_img3 corsor-pointer' /> */}
            </div>
          </StyledContainerLeft>
          <StyledHeader
            isMobile={matches}>
            <img onClick={() => { 
              window.location.href = '/'
             }} src="/images/home/logo.svg" alt=''  />
            <span className='AEGIS corsor-pointer'>AEGIS</span>

            <div>
               <Login
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
               >
                <div className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>{interceptAdd(account)}</div>
              </Login>
            </div>
          </StyledHeader>
          <StyledContainer1 isMobile={matches}>
            <StyledA1Left isMobile={matches}>
              <div className={Container1RefVisibility?.[0] ? 'Upgrade j' : 'Upgrade c'}>
                <span style={{color: '#FFFFFF'}}>Protect security and privacy on</span> <span className='avalanche'>{page1Num === 0 && 'Ethereum'}{page1Num === 1 && 'BNB Chain'}{page1Num === 2 && 'Polygon'}</span>
              </div>
              <div className={Container1RefVisibility?.[0] ? 'your j' : 'your  c'}>Your AI Security Guard of Web3</div>
              <div className={Container1RefVisibility?.[0] ? 'aegis j' : 'aegis  c'}>Aegis is web3 security guard powered by AI technology that helps to solve the security issues.</div>
              <div className={Container1RefVisibility?.[0] ? 'corsor-pointer join j' : 'corsor-pointer join  c'} 
                onClick={() => { 
                  if(account){
                    // history.push('/JoinWhitelist')
                    setWeb3FormIsModalOpen(true)
                  } else {
                    setIsModalOpen(true)
                  }
                }}
               >Join whitelist  <img style={{width: '25.29px', height: '25.29px', marginLeft: '15px'}} src="/images/home/right_z.png" alt=''  />
              </div>
            </StyledA1Left>
            <StyledA1Right isMobile={matches}>          
              <Dream>
                <main>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </main>
              </Dream>
              <div className={Container1RefVisibility?.[0] ? 'a a-j' : 'a a-c'}>
                <img className='img1' src="/images/home/A1.png" alt="" />
              </div>
              <div className={Container1RefVisibility?.[0] ? 'protect a-j' : 'protect a-c'}>
                AEGIS
              </div>
            </StyledA1Right>
          </StyledContainer1>
          {
            !cookies && 
            <Agreement 
              page={page}
              setCookies={setCookies}
              StyledContainer1Ref={StyledContainer1Ref}
              StyledContainer2Ref={StyledContainer2Ref}
              StyledContainer3Ref={StyledContainer3Ref}
              StyledContainer4Ref={StyledContainer4Ref}
              StyledContainer5Ref={StyledContainer5Ref}
              StyledContainer6Ref={StyledContainer6Ref}
            />
          }
        </div>
        <StyledContainer2 id="protect" isMobile={matches} ref={StyledContainer2Ref}>
          <div ref={cardRightRef} className={cardRightRefVisibility?.[0] ? 'cardRight' : 'cardRight-c'}>
            <img className='img1' src="/images/home/2-middle.png" alt="" />
            <img className='img2' src="/images/home/2-left.png" alt="" />
            <img className='img3' src="/images/home/2-right.png" alt="" />
          </div>
          <div className={cardRightRefVisibility?.[0] ? 'Container2Left Container2Left-j' : 'Container2Left Container2Left-c' }>
            <div className='aegis'>
              <span style={{color: '#FFFFFF'}}>Aegis</span> <span style={{color: '#9333EA'}}>Protect</span>
            </div>
            <div className='browser'>A browser extension keeps you away from scams.</div>
            <div className='buttonJon'>
              <div className='join corsor-pointer' onClick={() => { 
                  if(account){
                    // history.push('/JoinWhitelist')
                    setWeb3FormIsModalOpen(true)
                  } else {
                    setIsModalOpen(true)
                  }
                }}>Join whitelist   <img style={{width: '25.29px', height: '25.29px', marginLeft: '15px'}} src="/images/home/right_z.png" alt=''  /></div>
              <div className='joinImg'>
                <img className='img1 corsor-pointer' src="/images/home/sz.png" alt="" />
                <img className='img2 corsor-pointer' src="/images/home/gg.png" alt="" />
                <img className='img3 corsor-pointer' src="/images/home/sh.png" alt="" />
              </div>
            </div>
          </div>
        </StyledContainer2>
        {/* <StyledContainer3 id="wallet" isMobile={matches} ref={StyledContainer3Ref}>
          <div className={Container3RefVisibility?.[0] ? 'Container3Left Container3Left-j' : 'Container3Left Container3Left-c'}>
            <div className='aegis'><span style={{color: '#fff'}}>Aegis</span> <span style={{color: '#9333EA'}}>Wallet</span></div>
            <div className='a'>A mobile wallet that protects assets and preserves privacy.</div>
          </div>
          <div className={Container3RefVisibility?.[0] ? 'Container3Right' : 'Container3Right-C'}>
            <img className='img1' src="/images/home/3-middle.png" alt="" />
            <img className='img2' src="/images/home/3-left.png" alt="" />
            <img className='img3' src="/images/home/3-right.png" alt="" />
          </div>
        </StyledContainer3>   */}
        <StyledContainer4 isMobile={matches} ref={StyledContainer4Ref}>
          <div className='boxCircle'>
            <div className='circle'>
              A
            </div>
            <div className='circle1'/>
            <div className='circle2' />
            <div className='circle3' />
            <div className='circle4' />
            <div className={Container4RefVisibility?.[0] ? 'circle5 circle5-j' : 'circle5 circle5-c' }>
              <div>
                <div>
                  <img className='img1' src="/images/home/containerETH.png" alt="" />
                  <div>Ethereum</div>
                </div>
                <div style={{marginTop: 35}}>
                  <img className='img1' src="/images/home/c452.png" alt="" />
                  <div>BNB Chain</div>
                </div>
              </div>
              <div style={{marginLeft: 35}}>
                <div>
                  <img className='img1' src="/images/home/Polygon.png" alt="" />
                  <div>Polygon</div>
                </div>
                <div style={{marginTop: 35}}>
                  <div style={{width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                    <img style={{width: 35, height: 35}} className='img1' src="/images/home/more.svg" alt="" />
                  </div>
                  <div style={{marginTop: 10}}>More</div>
                </div>
              </div>
            </div>
            <div className={Container4RefVisibility?.[0] ? 'circle6 circle6-j' : 'circle6 circle6-c' }>
              <div>
                <div className='h_img1_box' style={{marginBottom: 30}}>
                  <div className='h_img1'>
                    <div className='h_img1_top'>NFTS</div>
                    <div className='h_img1_bom'>
                      <img className='img1' src="/images/home/4-1.jpg" alt="" />
                    </div>
                  </div>
                  <div className='h_img1' style={{marginLeft: 30}}>
                    <div className='h_img1_top'>DEFI</div>
                    <div className='h_img1_bom'>
                      <img className='img1' src="/images/home/4-2.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className='h_img1_box'>
                  <div className='h_img1'>
                    <div className='h_img1_top'>WALLETS</div>
                    <div className='h_img1_bom'>
                      <img className='img1' src="/images/home/4-3.jpg" alt="" />
                    </div>
                  </div>
                  <div className='h_img1' style={{marginLeft: 30}}>
                    <div className='h_img1_top'>GAMING</div>
                    <div className='h_img1_bom'>
                      <img className='img1' src="/images/home/4-4.jpg" alt="" />
                    </div>
                  </div>
                  {/* <img className='img1' src="/images/home/containeNFTr.png" alt="" />
                  <img style={{marginLeft: 8}} className='img1' src="/images/home/containeNFTr.png" alt="" /> */}
                </div>
              </div>
            </div>
            <div className={Container4RefVisibility?.[0] ? 'circle7 circle7-j' : 'circle7 circle7-c'}>
              <div>
                <img className='img1' src="/images/home/p_b.png" alt="" /><span className='farst-letter'>Protect</span>
              </div>
              <div>
                <img className='img1' src="/images/home/w_b.png" alt="" /><span className='farst-letter'>Wallet</span>
              </div>
              {/* <div>
                <img className='img1' src="/images/home/s.png" alt="" /><span>Shadow-mode</span>
              </div> */}
              <div>
                <img className='img1' src="/images/home/s_b.png" alt="" /><span className='farst-letter'>AI Security Brain</span>
              </div>
            </div>
          </div>
        </StyledContainer4>
        <StyledContainer5 isMobile={matches} ref={StyledContainer5Ref}>
          <div className='Container5Box'>
            <div className='circle'>
              <div className={Container5RefVisibility?.[0] ? 'circle-j' : 'circle-c' }>
                AI
              </div>
              <div className={Container5RefVisibility?.[0] ? 'security circle-j' : 'security circle-c' }>
                SECURITY BRAIN 
              </div>
            </div>
            <div className={Container5RefVisibility?.[0] ? 'ellipse ellipse-j' : 'ellipse ellipse-c' }>
              <div ref={StyledContainer4Num2} className={styledContainer4NumFour === 1 ? 'fourCircleBox transformBox1 fourCircleBoxHover': 'fourCircleBox transformBox1' }>
                <div  className='circleX'> <div /></div>
                <div>
                  <div className='txt1'>ALERTS</div>
                  <div className='txt2'>Alerts to malicious smart contracts and websites</div>
                </div>
              </div>

              
              <div ref={StyledContainer4Num1} className={styledContainer4NumFour === 0 ? 'fourCircleBox transformBox2 fourCircleBoxHover' : 'fourCircleBox transformBox2'}>
                <div  className='circleX'>  <div /></div>
                <div>
                  <div className='txt1'>TRANSPARENCY</div>
                  <div className='txt2'>Provide the transparency of transactions</div>
                </div>
              </div>

              
              <div ref={StyledContainer4Num4} className={styledContainer4NumFour === 3 ? 'fourCircleBox transformBox3 fourCircleBoxHover' : 'fourCircleBox transformBox3'}>
                <div  className='circleX'>  <div /></div>
                <div>
                  <div className='txt1'>SHADOW-MODE</div>
                  <div className='txt2'>Easy to create shadow wallets and preserve transaction privacy</div>
                </div>
              </div>

              
              <div  ref={StyledContainer4Num3} className={styledContainer4NumFour === 2 ? 'fourCircleBox transformBox4 fourCircleBoxHover' : 'fourCircleBox transformBox4'}>
                <div  className='circleX'>  <div /></div>
                <div>
                  <div className='txt1'>REVOKE</div>
                  <div className='txt2'>Allowances management</div>
                </div>
              </div>
            </div>

            <div className={Container5RefVisibility?.[0] ? 'txt3 txt3-j' : 'txt3 txt3-c'}>
              Based on security big data analysis, the Al security brain uses key capabilities such as threat intelligence, and knowledge base to improve the detection and discovery capabilities of scams and malicious activities.
            </div>
            
          </div>
        </StyledContainer5>
        <StyledContainer6 isMobile={matches} ref={StyledContainer6Ref}>
          
          <div className='Container7Box'>
              <img className={Container6RefVisibility?.[0] ? 'img1 circle6-j' : 'img1 circle6-c'} src="/images/home/container6_z.png" alt="" />
              <div className={Container6RefVisibility?.[0] ? 'txtBox txtBox1 circle6-j' : 'txtBox txtBox1  circle6-c' }>
                <div className={Container6RefVisibility?.[0] ? 'txt1 box-txt-j' : 'txt1 box-txt-c'}>Transparency</div>
                <div className={Container6RefVisibility?.[0] ? 'txt2 box-txt-j' : 'txt2 box-txt-c'}>Transaction simulation, know what you&apos;re signing </div>
                <div className='line line1'/>
              </div>
              
              <div className={Container6RefVisibility?.[0] ? 'txtBox txtBox2 circle6-j' : 'txtBox txtBox2  circle6-c' }>
                <div className={Container6RefVisibility?.[0] ? 'txt1 box-txt-j' : 'txt1 box-txt-c'}>Privacy</div>
                <div className={Container6RefVisibility?.[0] ? 'txt2 box-txt-j' : 'txt2 box-txt-c'}>Protect your privacy </div>
                <div className='line line2'/>
              </div>
              
              <div className={Container6RefVisibility?.[0] ? 'txtBox txtBox3 circle6-j' : 'txtBox txtBox3  circle6-c' }>
                <div className={Container6RefVisibility?.[0] ? 'txt1 box-txt-j' : 'txt1 box-txt-c'}>Usability</div>
                <div className={Container6RefVisibility?.[0] ? 'txt2 box-txt-j' : 'txt2 box-txt-c'}>Easy to use and user friendly experiences </div>
                <div className='line line3'/>
              </div>
              
              <div className={Container6RefVisibility?.[0] ? 'txtBox txtBox4 circle6-j' : 'txtBox txtBox4  circle6-c' }>
                <div className={Container6RefVisibility?.[0] ? 'txt1 box-txt-j' : 'txt1 box-txt-c'}>Chain-agnostic</div>
                <div className={Container6RefVisibility?.[0] ? 'txt2 box-txt-j' : 'txt2 box-txt-c'}>Multi-chain support </div>
                <div className='line line4'/>
              </div>
            </div>
            <div className={Container6RefVisibility?.[0] ? 'footer footer-j' : 'footer footer-c'}>
              <div className='footer-box'>
                <div className='footerLeft'>
                    <div style={{marginBottom: '13px'}}>
                      <img src="/images/home/logo.png" alt=''  /> <span>AEGIS</span>  
                      <div className='lmgbox'>
                        {/* <img className='img1' src="/images/home/baidu.png" alt="" />
                        <img className='img2' src="/images/home/huobi.png" alt="" />
                        <img className='img3' src="/images/home/motorolo.png" alt="" />
                        <img className='img4' src="/images/home/tenxun.png" alt="" />
                        <img className='img5' src="/images/home/360.png" alt="" />
                        <img className='img6' src="/images/home/openpasp.png" alt="" />
                        <img className='img7' src="/images/home/Teaclave.png" alt="" /> */}
                      </div>
                    </div>
                  </div>
                  
                  <div className='your_ai'>Your AI Security Guard of Web3</div>
                  <div className='footerRight'>
                    <span>Copyright @2022 Aegis Labs, Inc. All rights reserved.</span><span style={{marginLeft: '52px'}}>  <span className='corsor-pointer' onClick={() => { history.push('/TermsOfService') }}>Terms of Service</span> <span className='corsor-pointer' onClick={() => { history.push('/PrivacyPolicy') }}>Privacy Policy</span> <span style={{marginLeft: '185px'}}><a href="mailto:hi@aegis.top">hi@aegis.top</a></span></span>
                  </div> 
              </div>
            </div>
        </StyledContainer6>
      </main>
      <Web3Form web3FormIsModalOpen={web3FormIsModalOpen} setWeb3FormIsModalOpen={setWeb3FormIsModalOpen}/>
    </Wrapper>
  )
}
